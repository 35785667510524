import React from 'react'
import PropTypes from 'prop-types'
import Menu from 'features/menu/components/Menu'
import Footer from 'features/footer/components/Footer'
import '../styles/layout.scss'

const Layout = (props) => {
  const { children } = props

  return (
    <div className="main-layout">
      <Menu />

      <div className="main-container">
        {children}
      </div>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}

Layout.defaultProps = {
}

export default Layout
