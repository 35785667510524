import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import videojs from 'video.js'
import 'videojs-contrib-ads'
import 'videojs-ima'
import 'videojs-seek-buttons'
import 'video.js/dist/video-js.css'
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css'
import 'videojs-ima/dist/videojs.ima.css'
import './styles/video.scss'

const Video = (props) => {
  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const { type, options, onReady, src } = props

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      const player = playerRef.current = videojs(videoElement, options, () => {
        // Seek
        if (type === 'vod') {
          player.seekButtons({
            forward: 15,
            back: 15,
            forwardIndex: 2,
            backIndex: 2
          })
        }

        onReady && onReady(player)
      })
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current
      // player.autoplay(options.autoplay);
      player.src(src)
    }
  }, [options, videoRef, src])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <>
      {type === 'live'
        ? <video ref={videoRef} className="video-js vjs-default-skin vjs-fill vjs-big-play-centered" webkit-playsinline='true' playsInline />
        : <video ref={videoRef} className="video-js vjs-default-skin vjs-big-play-centered" webkit-playsinline='true' playsInline />
      }
    </>
  )
}

Video.propTypes = {
  type: PropTypes.string,
  options: PropTypes.any,
  onReady: PropTypes.any,
  src: PropTypes.any
}

Video.defaultProps = {
}

export default Video
