import React, { useEffect, useState, useRef } from 'react'
import { useInfiniteQuery } from 'react-query'
import { getData } from 'common/utils'
import { useMediaQuery } from 'common/hook-custom'
import Layout from 'features/layout/components/Layout'
import Search from '../components/Search'
import SearchMobile from '../components/SearchMobile'
import '../styles/search-page.scss'

const SearchPage = (props) => {
  const loadMoreRef = useRef()
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const [searchText, setSearchText] = useState('')
  const [data, setData] = useState([])

  const {
    data: dataSearch,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(['getSearch', searchText], async ({ pageParam = 0 }) => {
    if (searchText !== '') {
      return await getData('GET', `search/${searchText}/?platform=web&page=${pageParam}&limit=20`)
    } else {
      return []
    }
  }, {
    getNextPageParam: lastPage => {
      if (lastPage.metadata) {
        return (lastPage.metadata.page + 1) ?? false
      } else {
        return false
      }
    }
  })

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Tìm kiếm',
        page_location: 'https://thvli.vn/search',
        page_path: '/search',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Tìm kiếm'
  }, [])

  useEffect(() => {
    if (dataSearch) {
      setData(dataSearch.pages)
    }
  }, [dataSearch])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleChange = (e) => {
    setSearchText(e.target && e.target.value)
  }

  return (
    <Layout>
      <div className="search-page pt-2">
        <div className="row">
          <div className="col-md-12 input-search-container">
            <input className="form-control input-search" onChange={handleChange} placeholder="Tìm kiếm phim, diễn viên..." />
          </div>
        </div>

        <div className="mx-md-5 py-4 py-md-5 px-md-5 result-container">
          {isDesktop
            ? <Search
                data={data}
                loadMoreRef={loadMoreRef}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isLoading={isLoading}
                isFetchingNextPage={isFetchingNextPage}
              />
            : <SearchMobile
                data={data}
                loadMoreRef={loadMoreRef}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isLoading={isLoading}
                isFetchingNextPage={isFetchingNextPage}
              />
          }
        </div>
      </div>
    </Layout>
  )
}

export default SearchPage
