import axios from 'axios'
import MD5 from 'crypto-js/md5'
import UAParser from 'ua-parser-js'
import packageJson from '../../package.json'

/**
 * Gọi api, lấy dữ liệu
 */
const callApiServerTime = () => {
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}now/`
  })
}

const callApiPublisher = () => {
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}ads/get_display_publisher_per_position/?platform=WB_APP`
  })
}

export const getDataServerTime = () => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callApiServerTime()
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      console.log(e)
      return reject(e)
    }
  })
}

export const getDataPublisher = () => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callApiPublisher()
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      console.log(e)
      return reject(e)
    }
  })
}

const callApi = (method, endpoint, data) => {
  let currentTimestamp = Date.now()
  let timeDiff = 0

  if (localStorage.getItem('TIME_DIFF')) {
    timeDiff = parseInt(localStorage.getItem('TIME_DIFF'))
  }

  currentTimestamp = currentTimestamp - timeDiff

  const date = new Date(currentTimestamp)
  const day = date.getDate() < 10 ? ('0' + date.getDate()) : (date.getDate()).toString()
  const month = (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1).toString()
  const year = date.getFullYear().toString()
  const hour = date.getHours() < 10 ? ('0' + date.getHours()) : (date.getHours()).toString()
  const minute = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : (date.getMinutes()).toString()
  const second = date.getSeconds() < 10 ? ('0' + date.getSeconds()) : (date.getSeconds()).toString()

  const dateValue = year + month + day
  const timeValue = hour + minute + second
  const md5Value = (MD5(dateValue + timeValue)).toString()
  const keyValue = md5Value.substring(0, 3) + md5Value.substring(md5Value.length - 3)

  const keyAccess = process.env.REACT_APP_ACCESS_KEY_DATE + dateValue + process.env.REACT_APP_ACCESS_KEY_TIME + timeValue + process.env.REACT_APP_ACCESS_KEY_SECRET + keyValue

  return axios({
    headers: {
      'X-SFD-Key': MD5(keyAccess).toString(),
      'X-SFD-Date': dateValue + timeValue
    },
    method: method,
    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    data: data
  })
}

const callApiDetail = (method, endpoint, data) => {
  let currentTimestamp = Date.now()
  let timeDiff = 0

  if (localStorage.getItem('TIME_DIFF')) {
    timeDiff = parseInt(localStorage.getItem('TIME_DIFF'))
  }

  currentTimestamp = currentTimestamp - timeDiff

  const date = new Date(currentTimestamp)
  const day = date.getDate() < 10 ? ('0' + date.getDate()) : (date.getDate()).toString()
  const month = (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1).toString()
  const year = date.getFullYear().toString()
  const hour = date.getHours() < 10 ? ('0' + date.getHours()) : (date.getHours()).toString()
  const minute = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : (date.getMinutes()).toString()
  const second = date.getSeconds() < 10 ? ('0' + date.getSeconds()) : (date.getSeconds()).toString()

  const dateValue = year + month + day
  const timeValue = hour + minute + second
  const md5Value = (MD5(dateValue + timeValue)).toString()
  const keyValue = md5Value.substring(0, 3) + md5Value.substring(md5Value.length - 3)

  const keyAccess = process.env.REACT_APP_ACCESS_KEY_DATE + dateValue + process.env.REACT_APP_ACCESS_KEY_TIME + timeValue + process.env.REACT_APP_ACCESS_KEY_SECRET + keyValue

  const parserUserAgent = new UAParser()
  const resultUserAgent = parserUserAgent.getResult()

  return axios({
    headers: {
      'X-SFD-Key': MD5(keyAccess).toString(),
      'X-SFD-Date': dateValue + timeValue,
      'Custom-User-Agent': `${packageJson.version} (${resultUserAgent.os.name}/${resultUserAgent.os.version}; ${resultUserAgent.browser.name} ${resultUserAgent.browser.version}; pc)`
    },
    method: method,
    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    data: data
  })
}

const callApiAuth = (method, endpoint, data) => {
  return axios({
    headers: {
      Authorization: `${localStorage.getItem('adm_token')}`
    },
    method: method,
    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    data: data
  })
}

export const callApiAds = (videoId) => {
  return axios({
    method: 'GET',
    url: `https://ads.thvli.vn/api/configs/getAdConfig?videoId=${videoId}`
  })
}

export const callApiFaq = () => {
  return axios({
    method: 'GET',
    url: 'https://api.thvli.vn/backend/cas/public/faq/'
  })
}

export const callApiProfile = () => {
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_SETTING_URL}profile/`,
    headers: {
      Authorization: `${localStorage.getItem('adm_token')}`
    }
  })
}

export const getData = (endpoint, dataSend, method) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callApi(endpoint, dataSend, method)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      console.log(e)
      return reject(e)
    }
  })
}

export const getDataDetail = (endpoint, dataSend, method) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callApiDetail(endpoint, dataSend, method)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      console.log(e)
      return reject(e)
    }
  })
}

export const getDataAuth = (endpoint, dataSend, method) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callApiAuth(endpoint, dataSend, method)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      console.log(e)
      return reject(e)
    }
  })
}

export const getProfileData = () => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callApiProfile()
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      console.log(e)
      return reject(e)
    }
  })
}

/**
 * Format lại dữ liệu
 * Dùng trong trường hợp có danh sách ở nhiều dòng
 * @param {array} data Mảng dữ liệu
 * @param {number} chunk Số item trên 1 dòng
 */
export const formatDataChunk = (data, chunk) => {
  if (data.items) {
    let i
    let j
    const tempArr = []
    for (i = 0, j = data.items.length; i < j; i += chunk) {
      const sliceArr = data.items.slice(i, i + chunk)
      tempArr.push(sliceArr)
    }

    return tempArr
  } else {
    return []
  }
}

export const formatDataChunkCustom = (data, chunk) => {
  if (Array.isArray(data)) {
    let i
    let j
    const tempArr = []
    for (i = 0, j = data.length; i < j; i += chunk) {
      const sliceArr = data.slice(i, i + chunk)
      tempArr.push(sliceArr)
    }

    return tempArr
  } else {
    return []
  }
}

export const formatDate = (value, type) => {
  if (value) {
    const date = new Date(value)
    const day = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate()
    const month = (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1
    const year = date.getFullYear()

    if (type === 'dd/mm/yyyy') {
      return day + '/' + month + '/' + year
    } else {
      return year + '-' + month + '-' + day
    }
  } else {
    return ''
  }
}

export const formatTime = (value, type) => {
  if (value) {
    const date = new Date(value * 1000)
    const hour = date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours()
    const minute = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()

    if (type === 'hh:mm') {
      return hour + ':' + minute
    } else {
      return hour + ':' + minute
    }
  } else {
    return ''
  }
}

export const msToTime = (s) => {
  // let milliseconds = Math.floor((duration % 1000) / 100),
  //   seconds = Math.floor((duration / 1000) % 60),
  //   minutes = Math.floor((duration / (1000 * 60)) % 60),
  //   hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  // hours = hours < 10 ? "0" + hours : hours;
  // minutes = minutes < 10 ? "0" + minutes : minutes;
  // seconds = seconds < 10 ? "0" + seconds : seconds;

  // return hours + ":" + minutes + ":" + seconds;

  const ms = s % 1000
  s = (s - ms) / 1000
  const secs = s % 60
  s = (s - secs) / 60
  const mins = s % 60

  const hrsTemp = (s - mins) / 60

  const day = Math.floor(hrsTemp / 24)
  const hrs = Math.floor(hrsTemp % 24)

  const hours = hrs < 10 ? '0' + hrs : hrs
  const minutes = mins < 10 ? '0' + mins : mins
  const seconds = secs < 10 ? '0' + secs : secs

  return day > 0 ? (day + ' ngày ' + hours + ' giờ ' + minutes + ' phút ' + seconds + ' giây') : (hours + ' giờ ' + minutes + ' phút ' + seconds + ' giây')
}

export const msToTimeRibbon = (s) => {
  // let milliseconds = Math.floor((duration % 1000) / 100),
  //   seconds = Math.floor((duration / 1000) % 60),
  //   minutes = Math.floor((duration / (1000 * 60)) % 60),
  //   hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  // hours = hours < 10 ? "0" + hours : hours;
  // minutes = minutes < 10 ? "0" + minutes : minutes;
  // seconds = seconds < 10 ? "0" + seconds : seconds;

  // return hours + ":" + minutes + ":" + seconds;

  const ms = s % 1000
  s = (s - ms) / 1000
  const secs = s % 60
  s = (s - secs) / 60
  const mins = s % 60

  const hrsTemp = (s - mins) / 60

  const day = Math.floor(hrsTemp / 24)
  const hrs = Math.floor(hrsTemp % 24)

  const hours = hrs < 10 ? '0' + hrs : hrs
  const minutes = mins < 10 ? '0' + mins : mins

  return day > 0 ? (day + ' ngày ' + hours + ' giờ ' + minutes + ' phút') : (hours + ' giờ ' + minutes + ' phút')
}

export const parseDate = (value) => {
  if (typeof value === 'string') {
    const date = new Date(value.replace(/-/g, '/'))

    if (!isNaN(date)) {
      const day = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate()
      const month = (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1
      const year = date.getFullYear()
      return day + '/' + month + '/' + year
    } else {
      return ''
    }
  } else {
    return ''
  }
}

/**
 * Scroll theo chiều dọc khi navigation
 * @param {string} parentId Id của parent
 * @param {string} childId Id của child
 * @param {string} direction Hướng navigation (UP or DOWN)
 */
export const scrollVertical = (parentId, childId, direction) => {
  const parentEl = document.getElementById(parentId)
  const childEl = document.getElementById(childId)

  if (parentEl && childEl) {
    const parentHeight = parentEl.clientHeight
    const parentOffsetTop = parentEl.offsetTop
    const parentScrollTop = parentEl.scrollTop
    const childHeight = childEl.clientHeight
    const childOffsetTop = childEl.offsetTop

    // Tính offset top để check overflow
    const offsetTop = childOffsetTop - parentOffsetTop - parentScrollTop

    if (direction === 'UP') {
      // Chỉ scroll khi overflow
      if (offsetTop < parentHeight) {
        parentEl.scrollTop = childOffsetTop - parentOffsetTop
      }
    } else if (direction === 'DOWN') {
      const childOffsetBottom = offsetTop + childHeight

      // Chỉ scroll khi overflow
      if (childOffsetBottom > parentHeight) {
        parentEl.scrollTop = childOffsetTop - parentOffsetTop
      }
    }
  }
}

/**
 * Scroll theo chiều dọc khi navigation
 * @param {string} parentId Id của parent
 * @param {string} childId Id của child
 * @param {string} direction Hướng navigation (UP or DOWN)
 */
export const scrollVerticalCenter = (parentId, childId, direction) => {
  const parentEl = document.getElementById(parentId)
  const childEl = document.getElementById(childId)

  if (parentEl && childEl) {
    const parentHeight = parentEl.clientHeight
    const parentOffsetTop = parentEl.offsetTop
    const parentScrollTop = parentEl.scrollTop
    const childHeight = childEl.clientHeight
    const childOffsetTop = childEl.offsetTop

    // Tính offset top để check overflow
    const offsetTop = childOffsetTop - parentOffsetTop - parentScrollTop

    if (direction === 'UP') {
      // Chỉ scroll khi overflow
      if (offsetTop < parentHeight) {
        parentEl.scrollTop = childOffsetTop - parentOffsetTop
      }
    } else if (direction === 'DOWN') {
      const childOffsetBottom = offsetTop + childHeight

      // Chỉ scroll khi overflow
      if (childOffsetBottom > parentHeight) {
        parentEl.scrollTop = childOffsetTop - parentOffsetTop - (parentHeight / 2) + 25
      }
    }
  }
}

export const convertToPlain = (html) => {
  // Create a new div element
  const tempDivElement = document.createElement('div')

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html

  const htmlValue =
    tempDivElement.textContent || tempDivElement.innerText || ''

  tempDivElement.remove()

  // Retrieve the text property of the element
  return htmlValue
}

const AGE_RATING = 0
const CONTENT_RATING = 1

export const getAgeContentRating = (contentRatings) => {
  if (!contentRatings) return null
  const limitAge = 0
  try {
    const ageRating = (contentRatings || []).filter(
      rating => rating?.type === AGE_RATING && (rating?.min_age ?? -1) >= limitAge
    )
    return ageRating.length > 0 ? ageRating[0] : null
  } catch (error) {
    return null
  }
}

export const getContentRatingList = (contentRatings) => {
  if (!contentRatings) return []
  try {
    const conRating = (contentRatings || []).filter(rating => rating?.type === CONTENT_RATING && rating?.name !== '')
    return conRating
  } catch (error) {
    return []
  }
}
