import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useIntersectionObserver from 'common/hook'
import Spinner from 'components/Spinner'
import { formatDataChunk } from 'common/utils'
import ImageHolder from 'features/core/ImageHolder'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import RibbonItemLiveEvent from 'features/ribbon/components/RibbonItemLiveEvent'
import '../styles/search-mobile.scss'

const SearchMobile = (props) => {
  const { data, loadMoreRef, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = props

  // Infinite Loading
  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage
  })

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */

  return (
    <div className="search-mobile">
      {data && Array.isArray(data) && data.map((item, index) => {
        const dataChunk = formatDataChunk(item, 4)

        return (
          <div key={index}>
            {dataChunk && dataChunk.map((itemChild, indexChild) => {
              return (
                <div key={indexChild} className="row">
                  {itemChild && itemChild.map((itemGrandChild, indexGrandChild) => {
                    return (
                      <React.Fragment key={indexGrandChild}>
                        <div className="col-6 col-md-3 item-figure pt-1">
                          <Link to={`/${itemGrandChild.type === 10 ? 'truc-tiep' : itemGrandChild.type === 5 ? 'live' : 'detail'}/${itemGrandChild.slug}`}>
                            <figure className="figure">
                              <div className="figure-overflow">
                                <AgeRatingItem item={itemGrandChild} />
                                <NewReleaseItem
                                  item={itemGrandChild}
                                  // isPoster={item?.type === 1}
                                  isPoster
                                />

                                <RibbonItemLiveEvent data={itemGrandChild} />

                                <ImageHolder data={itemGrandChild.images} />
                              </div>
                              <figcaption className="figure-caption">
                                {itemGrandChild.title}
                              </figcaption>
                            </figure>
                          </Link>
                        </div>

                        {/* {((indexGrandChild === itemChild.length - 1) && itemChild.length === 1) &&
                          <>
                            <div className='col-6'></div>
                          </>
                        } */}
                      </React.Fragment>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )
      })}

      <div ref={loadMoreRef} className="row"></div>

      {isLoading && <Spinner />}

      {isFetchingNextPage && <div></div>}
    </div>
  )
}

SearchMobile.propTypes = {
  data: PropTypes.array,
  loadMoreRef: PropTypes.any,
  fetchNextPage: PropTypes.any,
  hasNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool
}

SearchMobile.defaultProps = {
  data: [],
  hasNextPage: false,
  isLoading: false,
  isFetchingNextPage: false
}

export default SearchMobile
