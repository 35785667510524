import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setProgress } from 'features/watching/watchingSlice'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { Link, useLocation, useParams } from 'react-router-dom'
import ImageHolder from 'features/core/ImageHolder'
import ImagePosterHolder from 'features/core/ImagePosterHolder'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import RibbonItemLiveEvent from './RibbonItemLiveEvent'
import RibbonItemTopMobile from './RibbonItemTopMobile'
import '../styles/ribbon-mobile.scss'

const RibbonMobile = (props) => {
  const { data } = props

  const location = useLocation()
  const { slug } = useParams()
  const dispatch = useDispatch()

  const [watchingList, setWatchingList] = useState([])

  useEffect(() => {
    if (data) { // load đồng bộ với ribbon
      if (localStorage.getItem('adm_token')) {
        axios.get(`${process.env.REACT_APP_API_URL}progress/?page=0&limit=15`, {
          headers: {
            Authorization: `${localStorage.getItem('adm_token')}`
          }
        })
          .then(res => {
            const dataTemp = res.data && res.data.items

            if (dataTemp && Array.isArray(dataTemp)) {
              setWatchingList(dataTemp)
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }, [data])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickWatching = (e, progress) => {
    dispatch(setProgress(progress))
  }

  return (
    <div className="ribbon-mobile px-0 px-md-4 pt-3 pt-md-5">
      {(watchingList && watchingList.length > 0) &&
        <div>
        <div className="row pt-4">
          <div className="col-md-12">
            <h3 className="ribbon-title ps-1 ps-md-2 pb-0 pb-md-1">
              <span>Đang xem</span>
              <Link to="/watching">
                <span className="float-end">Tất cả &#8250;</span>
              </Link>
            </h3>
          </div>
        </div>

        <div className="row container-wrapper">
          <div className="col-md-12">
            <Slider {...{
              className: 'watching-ribbon',
              dots: false,
              infinite: false,
              swipeToSlide: false,
              speed: 500,
              initialSlide: 0,
              slidesToShow: 5,
              slidesToScroll: 5,
              responsive: [
                { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
              ]
            }}>
              {watchingList && Array.isArray(watchingList) && watchingList.map((itemChild, indexChild) => {
                return (
                  <div key={indexChild} className="px-1 px-md-2 pb-1 pb-md-4 item-figure">
                    <Link to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/dang-xem/`)}${(itemChild.default_episode && itemChild.default_episode.id) ? itemChild.default_episode.id : itemChild.slug}`} onClick={e => handleClickWatching(e, itemChild.progress)}>
                      <figure className="figure">
                        <div className="figure-overflow">
                          <ImageHolder data={itemChild.images} />
                        </div>
                        <figcaption className="figure-caption">
                          {itemChild.title}
                        </figcaption>
                      </figure>
                    </Link>
                  </div>
                )
              })}

              {(watchingList.length === 1) &&
                <div className="pb-1 pb-md-4">
                  <figure className="figure px-1 px-md-2 figure-hide-item">
                    <div className="figure-overflow">
                      <ImageHolder />
                    </div>
                  </figure>
                </div>
              }
            </Slider>
          </div>
        </div>
      </div>
      }

      {data && data.map((item, index) => {
        const itemArr = item.items

        if ((Array.isArray(itemArr) && itemArr.length > 0)) {
          return (
            <div key={index}>
              <div className="row pt-4">
                <div className="col-md-12">
                  <h3 className="ribbon-title ps-1 ps-md-2 pb-0 pb-md-1">
                    <span>{item.name}</span>
                    <Link to={`${(location.pathname && location.pathname !== '' && location.pathname !== '/') ? location.pathname : '/page/trang-chu'}/${item.slug}/tat-ca`}>
                      <span className="float-end">Tất cả &#8250;</span>
                    </Link>
                  </h3>
                </div>
              </div>

              <div className="row container-wrapper">
                <div className="col-md-12">
                  <Slider {...{
                    className: item.id === '4818436b-d7bf-4867-85ca-64203b11c694' ? 'live-ribbon' : 'vod-ribbon',
                    dots: false,
                    infinite: false,
                    swipeToSlide: false,
                    speed: 500,
                    initialSlide: 0,
                    slidesToShow: item.type === 1 ? 3 : 2,
                    slidesToScroll: item.type === 1 ? 3 : 2,
                    responsive: [
                      { breakpoint: 480, settings: { slidesToShow: item.type === 1 ? 3 : 2, slidesToScroll: item.type === 1 ? 3 : 2 } },
                      { breakpoint: 687, settings: { slidesToShow: item.type === 1 ? 3 : 2, slidesToScroll: item.type === 1 ? 3 : 2 } },
                      { breakpoint: 768, settings: { slidesToShow: item.type === 1 ? 3 : 2, slidesToScroll: item.type === 1 ? 3 : 2 } },
                      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                      { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                    ]
                  }}>
                    {item && item.items && item.items.map((itemChild, indexChild) => {
                      if (item?.type === 5) {
                        return (
                          <RibbonItemTopMobile
                            key={indexChild}
                            indexChild={indexChild}
                            item={item}
                            itemChild={itemChild}
                            slug={slug}
                          />
                        )
                      } else {
                        return (
                          <div key={indexChild} className="px-1 px-md-2 pb-1 pb-md-4 item-figure">
                            <Link to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/dang-xem/`)}${itemChild.slug}`}>
                              <figure className="figure">
                                <div className={`figure-overflow ${item.type === 1 ? 'overflow_master_banner_mobile' : ''}`}>
                                  <AgeRatingItem item={itemChild} />

                                {/* <div className="figure-overflow"> */}
                                  <NewReleaseItem
                                    item={itemChild}
                                    // isPoster={item?.type === 1}
                                    isPoster
                                  />

                                  <RibbonItemLiveEvent data={itemChild} />

                                  {item.type === 1
                                    ? <ImagePosterHolder data={itemChild.images} />
                                    : <ImageHolder data={itemChild.images} />
                                  }
                                  {/* <ImageHolder data={itemChild.images} /> */}
                                </div>
                                <figcaption className="figure-caption">
                                  {itemChild.title}
                                </figcaption>
                              </figure>
                            </Link>
                          </div>
                        )
                      }
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          )
        } else {
          return null
        }
      })}
    </div>
  )
}

RibbonMobile.propTypes = {
  data: PropTypes.array
}

RibbonMobile.defaultProps = {
  data: []
}

export default RibbonMobile
