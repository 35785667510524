import React from 'react'
import Layout from 'features/layout/components/Layout'
import Detail from '../components/Detail'

const HomePage = (props) => {
  return (
    <Layout>
      <Detail />
    </Layout>
  )
}

export default HomePage
