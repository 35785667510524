import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getData, getDataServerTime, getDataPublisher } from 'common/utils'
import { setBannerList } from 'features/banner/bannerSlice'
import { setRibbonList } from 'features/ribbon/ribbonSlice'
import { useMediaQuery, useScript } from 'common/hook-custom'
import { DFPSlotsProvider, AdSlot } from 'react-dfp'
import Overlay from 'components/Overlay'
import Menu from 'features/menu/components/Menu'
import Ribbon from 'features/ribbon/components/Ribbon'
import RibbonEuro from 'features/ribbon/components/RibbonEuro'
import Banner from 'features/banner/components/Banner'
import Footer from 'features/footer/components/Footer'
import RibbonMobile from 'features/ribbon/components/RibbonMobile'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../styles/home.scss'

const HomePage = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const menu = useSelector((state) => state.menu.menu)
  const ribbonList = useSelector((state) => state.ribbon.ribbonList)
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  useScript('https://jsc.mgid.com/t/h/thvli.vn.1345023.js')

  const [menuId, setMenuId] = useState(null)
  const [publisher, setPublisher] = useState(null)

  const { data: dataPage, isLoading, isFetching } = useQuery(['getPage', menuId], async () => {
    if (menuId) {
      if (menuId === process.env.REACT_APP_MENU_HOT_ID) {
        return await getData('GET', `special-page/${process.env.REACT_APP_MENU_HOT_ID}/`)
      } else if (menuId === process.env.REACT_APP_MENU_EURO_ID) {
        // return await getData('GET', `special-page/${process.env.REACT_APP_MENU_EURO_ID}/`)
        return await getData('GET', `special-page/${process.env.REACT_APP_MENU_EURO_SLUG}/`)
      } else {
        return await getData('GET', `page/${menuId}/?platform=web`)
      }
    }
  })

  const { data: dataServerTime } = useQuery('getServerTime', async () => {
    return await getDataServerTime()
  }, {
    staleTime: 0,
    cacheTime: 0
  })

  const { data: dataPublisher } = useQuery('getPublisher', async () => {
    return await getDataPublisher()
  })

  useEffect(() => {
    document.title = 'Trang Chủ - THVLi'

    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Trang chủ',
        page_location: 'https://thvli.vn/',
        page_path: '/',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    if (localStorage.getItem('adm_redirect_url')) {
      localStorage.removeItem('adm_redirect_url')
    }
  }, [])

  useEffect(() => {
    if (menu) {
      document.title = menu.name
      setMenuId(menu.id)
    }
  }, [menu])

  useEffect(() => {
    if (dataPage) {
      dispatch(setBannerList(dataPage.banners))
      dispatch(setRibbonList(dataPage.ribbons))
    }
  }, [dataPage])

  useEffect(() => {
    if (dataServerTime) {
      const currentTimestamp = Date.now()
      const timeDiff = currentTimestamp - dataServerTime
      localStorage.setItem('TIME_NOW', currentTimestamp)
      localStorage.setItem('TIME_SERVER', dataServerTime)
      localStorage.setItem('TIME_DIFF', timeDiff)
    }
  }, [dataServerTime])

  useEffect(() => {
    if (dataPublisher) {
      const resultData = dataPublisher.data
      if (resultData) {
        setPublisher(resultData.POS001)
      }
    }
  }, [dataPublisher])

  useEffect(() => {
    if (publisher === 'gam' || publisher === 'GAM') {
      const adElement = document.getElementById('mgid-ad-container')
      if (adElement) {
        adElement.style.display = 'none'
      }
    }
  }, [publisher])

  useEffect(() => {
    if (!isLoading && !isFetching) {
      if (publisher === 'gam' || publisher === 'GAM') {
        const adElement = document.getElementById('mgid-ad-container')
        if (adElement) {
          adElement.style.display = 'none'
        }
      }
    }
  }, [isLoading, isFetching])

  return (
    // <DFPSlotsProvider dfpNetworkId='23128043035'>
    <>
      <DFPSlotsProvider dfpNetworkId='23128043035'>
        <Menu />

        {(isLoading || isFetching)
          ? <div className="homepage">
              <div className="skeleton-content"></div>
              <div className="skeleton-block-one"></div>

              <div className="d-flex skeleton-block-container">
                <div className="skeleton-block"></div>
                <div className="skeleton-block"></div>
                <div className="skeleton-block"></div>
                <div className="skeleton-block mb-5"></div>
              </div>
              <Overlay />
            </div>
          : <div className="homepage">
          <Banner />
            {/* <Menu style={{ paddingTop: 0 }} /> */}

            <div id="mgid-ad-container">
              <div className="banner-middle-ads" id="M763376ScriptRootC1345023"></div>
            </div>

            {(publisher === 'gam' || publisher === 'GAM') &&
              <div className="banner-middle-ads">
                <AdSlot sizes={[[970, 250]]} adUnit='THVLi_Desktop_Top_Banner_970x250' />
              </div>
            }

            {isDesktop
              ? <>
                  {location?.pathname === '/page/euro-2024-web'
                    ? <RibbonEuro
                        data={ribbonList}
                      />
                    : <Ribbon
                        data={ribbonList}
                      />
                  }
                </>
              : <>
                  {location?.pathname === '/page/euro-2024-web'
                    ? <RibbonEuro
                        data={ribbonList}
                      />
                    : <RibbonMobile
                        data={ribbonList}
                      />
                  }
                </>
            }
          </div>
        }

        <Footer />
        {/* </DFPSlotsProvider> */}
      </DFPSlotsProvider>
    </>
  )
}

export default HomePage
