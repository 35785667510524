import React, { useEffect, useState } from 'react'
import { convertToPlain } from 'common/utils'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { imgholder } from 'constant/images'
import ModalFigure from 'features/core/ModalFigure'
import { setProgress } from 'features/watching/watchingSlice'
import 'bootstrap/js/dist/carousel'
import { Play, InfoCircle } from 'iconsax-react'
// import icPlay from '../styles/images/ic_play.png'
// import icInfo from '../styles/images/ic_info.png'
import '../styles/banner.scss'

const Banner = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const bannerList = useSelector((state) => state.banner.bannerList)

  const [slugPath, setSlugPath] = useState(null)
  const [currentBannerItem, setCurrentBannerItem] = useState(null)
  const [currentBannerDescription, setCurrentBannerDescription] = useState('')

  const [modalContentId, setModalContentId] = useState(null)
  const [modalContentSlug, setModalContentSlug] = useState(null)
  const [modalContentImage, setModalContentImage] = useState(null)
  const [modalContentDescription, setModalContentDescription] = useState(null)
  const [modalContentPublishDate, setModalContentPublishDate] = useState(null)
  const [modalContentNewRelease, setModalContentNewRelease] = useState(null)
  const [modalProgress, setModalProgress] = useState(null)
  const [isShowModal, setIsShowModal] = useState(false)

  useEffect(() => {
    if (Array.isArray(bannerList) && bannerList.length > 0) {
      const firstItem = bannerList[0]
      if (firstItem) {
        setCurrentBannerItem(firstItem)
        setCurrentBannerDescription(convertToPlain(firstItem.long_description))
      }

      const carouselEl = document.getElementById('bannerCarousel')
      if (carouselEl) {
        carouselEl.addEventListener('slide.bs.carousel', (e) => {
          const indexTemp = e?.to
          if (indexTemp >= 0) {
            const bannerItemTemp = bannerList[indexTemp]
            setCurrentBannerItem(bannerItemTemp)
            setCurrentBannerDescription(convertToPlain(bannerItemTemp?.long_description))
          }
        })
      }
    }
  }, [bannerList])

  useEffect(() => {
    if (location) {
      if (location.pathname && location.pathname === '/') {
        setSlugPath('/trang-chu')
      } else {
        const urlPathArr = location.pathname.split('/')
        setSlugPath(`/${urlPathArr[urlPathArr.length - 1]}`)
      }
    }
  }, [location])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickShowModal = (e, item) => {
    if (item.images && item.images.cover && item.images.cover !== '') {
      setModalContentImage(item.images
        ? ((item.images.cover && item.images.cover) !== '' ? item.images.cover : imgholder)
        : imgholder)
    } else {
      setModalContentImage(item.images
        ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
        : imgholder)
    }

    const strippedHtml = convertToPlain(
      item.long_description
    )
    setModalContentDescription(strippedHtml)

    setModalContentPublishDate(item.publish_date)
    setModalContentNewRelease(item.new_release)

    setModalContentId(item.id)
    setModalContentSlug((item.default_episode && item.default_episode.id) ? item.default_episode.id : item.slug)
    setModalProgress(item.progress)
    setIsShowModal(true)
  }

  const handleCloseModal = (value) => {
    setIsShowModal(value)
    setModalContentId(null)
  }

  const handleClickWatching = (e, progress) => {
    dispatch(setProgress(progress))
  }

  return (
    <div className={(Array.isArray(bannerList) && bannerList.length > 0) ? '' : 'mt-5'}>
      {(Array.isArray(bannerList) && bannerList.length > 0) &&
        <div id="bannerCarousel" className="banner carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            {bannerList && bannerList.map((item, index) => {
              return (
                <button
                  key={index}
                  className={index === 0 ? 'active' : ''}
                  type="button"
                  data-bs-target="#bannerCarousel"
                  data-bs-slide-to={index}
                ></button>
              )
            })}
          </div>
          {/* <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div> */}
          <div className="carousel-inner">
            <div className='ribbon-banner-content-info'>
              <div>
                {currentBannerItem?.images?.content_logo
                  ? <div className='content-logo-wrapper'><img src={currentBannerItem?.images.content_logo} /></div>
                  : <div className='content-title'>{currentBannerItem?.title}</div>}

                <div className="description">{currentBannerDescription}</div>
                {/* <div className='ribbon-banner-content-metadata'>
                  <div className="ribbon-banner-content-metadata-view"><i className="bi bi-eye eye-icon"></i><span className="view-text">{formatThousand(item.views)}</span></div>
                  <div className='ribbon-banner-content-metadata-season'>{currentBannerItem?.publish_date ? currentBannerItem?.publish_date.slice(0, 4) : ''}</div>
                </div> */}
              </div>

              <div className='ribbon-banner-content-metadata-group-button'>
                {/* <Link to={`${currentBannerItem?.type === 5 ? '/live/' : `/detail${slugPath}`}/banner/${currentBannerItem?.slug}`}> */}
                <Link to={`${currentBannerItem?.type === 10 ? `/truc-tiep/${currentBannerItem?.slug}` : currentBannerItem?.type === 5 ? '/live/' : `/detail${slugPath}`}/banner/${currentBannerItem?.slug}`}>
                  <div className="watch-now-button">
                    {/* <i className="bi bi-play-fill watch-now-button-icon"></i> */}
                    {/* <img src={icPlay} /> */}
                    <Play color="black" variant='Bold' size={20} />
                    <span className='watch-now-button-text'>Xem Ngay</span>
                  </div>
                </Link>

                <div className="info-button" onClick={e => handleClickShowModal(e, currentBannerItem)}>
                  {/* <i className="bi bi-play-fill watch-now-button-icon"></i> */}
                  <InfoCircle color="white" variant='Bold' size={20} />
                  {/* <img src={icInfo} /> */}
                  <span className='info-button-text'>Thông tin</span>
                </div>
              </div>
            </div>

            {bannerList && bannerList.map((item, index) => {
              return (
                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                  <div className='ribbon-banner-left-gradient' />
                  <div className='ribbon-banner-right-gradient' />
                  <div className='ribbon-banner-bottom-gradient' />

                  <img
                    // className="d-block w-100 img-banner"
                    className="ribbon-banner-image"
                    // src={item.images
                    //   ? ((item.images.banner && item.images.banner) !== '' ? item.images.banner : imgholder)
                    //   : imgholder
                    // }
                    src={item?.images?.master_banner_tv || item?.images?.poster}
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src = imgholder
                    }}
                  />
                </div>
              )
            })}
          </div>

          <button className="carousel-control-prev" type="button" data-bs-target="#bannerCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#bannerCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>

          <ModalFigure
            type="banner"
            isShow={isShowModal}
            modalContentId={modalContentId}
            modalContentSlug={modalContentSlug}
            modalContentImage={modalContentImage}
            modalContentDescription={modalContentDescription}
            modalContentPublishDate={modalContentPublishDate}
            modalContentNewRelease={modalContentNewRelease}
            onClose={handleCloseModal}
            onClickPlay={e => handleClickWatching(e, modalProgress)}
          />
        </div>
      }
    </div>
  )
}

export default Banner
