import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useScript } from 'common/hook-custom'
import { getData, getDataDetail, getDataServerTime, getAgeContentRating, msToTime } from 'common/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import Overlay from 'components/Overlay'
import ModalReportError from 'features/core/ModalReportError'
import VideoLiveEvent from 'components/VideoLiveEvent'
import DetailInfo from './DetailInfo'
import DetailAds from './DetailAds'
import '../styles/detail-live-event.scss'

const DetailLiveEvent = (props) => {
  // const history = useHistory()
  const { slug } = useParams()
  const ref = useRef(null)

  useScript('https://jsc.mgid.com/t/h/thvli.vn.1404321.js')
  useScript('https://jsc.mgid.com/t/h/thvli.vn.1404323.js')

  const [ageRating, setAgeRating] = useState(null)

  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState(null)
  const [src, setSrc] = useState(null)
  const [player, setPlayer] = useState(null)
  const [title, setTitle] = useState('')
  const [hashtagList, setHastagList] = useState([])

  const [isShowModal, setIsShowModal] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [isLive, setIsLive] = useState(false)
  const [isTrailer, setIsTrailer] = useState(false)
  const [countdownTime, setCountdownTime] = useState('idle')

  const [dataPlayInfo, setDataPlayInfo] = useState(null)

  const timeoutRef = useRef(null)
  const intervalRef = useRef(null)
  const intervalEndRef = useRef(null)

  const { data, isError, error, refetch } = useQuery(['getDetailLiveEvent', slug], async () => {
    return await getDataDetail('GET', `get_detail/${slug}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
  }, {
    retry: false,
    cacheTime: 0,
    staleTime: 0
  })

  const eventListener = useCallback(() => {
  }, [data, player])

  useEffect(() => {
    if (isError && error) {
      if (error?.request?.status === 403) {
        const fetchDataServerTime = async () => {
          const resultServerTime = await getDataServerTime()
          if (resultServerTime) {
            const currentTimestamp = Date.now()
            const timeDiff = currentTimestamp - resultServerTime
            localStorage.setItem('TIME_NOW', currentTimestamp)
            localStorage.setItem('TIME_SERVER', resultServerTime)
            localStorage.setItem('TIME_DIFF', timeDiff)
          }

          setTimeout(() => {
            refetch()
          }, 200)
        }

        fetchDataServerTime()
      }
    }
  }, [isError, error])

  const handleIntervalEnd = (dataParam) => {
    const now = new Date()

    if (typeof dataParam?.event_start_time === 'number' && typeof dataParam?.event_end_time === 'number') {
      const eventStartTime = new Date(dataParam?.event_start_time * 1000)
      const eventEndTime = new Date(dataParam?.event_end_time * 1000)

      if (eventStartTime && eventEndTime) {
        if (now.getTime() < eventStartTime.getTime()) {
          //
        } else if (now.getTime() > eventEndTime.getTime()) {
          setIsLive(false)
          setCountdownTime(null)
          clearInterval(intervalRef.current)
          clearInterval(intervalEndRef.current)
          clearTimeout(timeoutRef.current)

          window.location.reload()
        } else {
          //
        }
      }
    }
  }

  const handleIntervalEvent = (dataParam) => {
    const now = new Date()

    if (typeof dataParam?.event_start_time === 'number' && typeof dataParam?.event_end_time === 'number') {
      const eventStartTime = new Date(dataParam?.event_start_time * 1000)
      const eventEndTime = new Date(dataParam?.event_end_time * 1000)

      if (eventStartTime && eventEndTime) {
        if (now.getTime() < eventStartTime.getTime()) {
          const countdownTimeTemp = eventStartTime.getTime() - now.getTime()
          setIsLive(false)
          setCountdownTime(msToTime(countdownTimeTemp))
        } else if (now.getTime() > eventEndTime.getTime()) {
          setIsLive(false)
          setCountdownTime(null)
        } else {
          setCountdownTime(1)
          setIsLive(true)
          clearInterval(intervalRef.current)

          const fetchPlayInfo = async () => {
            const playInfoResult = await getData('GET', `get_play_info/${dataParam?.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
            if (playInfoResult) {
              setDataPlayInfo(playInfoResult)
              const linkPlayInfo = playInfoResult.play_info?.data?.hls_link_play

              if (linkPlayInfo && linkPlayInfo !== '') {
                setSrc({
                  type: 'application/x-mpegURL',
                  src: linkPlayInfo
                })

                intervalEndRef.current = setInterval(() => {
                  handleIntervalEnd(dataParam)
                }, [1000])
              }
            }
          }

          fetchPlayInfo()
        }
      }
    }
  }

  useEffect(() => {
    window.onpopstate = e => {
      window.location.reload()
    }

    setIsLoading(true)

    return () => {
      clearInterval(intervalRef.current)
      clearInterval(intervalEndRef.current)
      clearTimeout(timeoutRef.current)
    }
  }, [])

  useEffect(() => {
    if (player) {
      player.on('pause', () => {
        const overlayAds = document.getElementById('video-overlay-ads')

        if (!player.seeking() && player.paused()) {
          if (overlayAds) {
            overlayAds.style.display = 'block'
          }
        }
      })
      player.on('playing', function () {
        const overlayAds = document.getElementById('video-overlay-ads')
        if (overlayAds) {
          overlayAds.style.display = 'none'
        }
      })

      player.on('ended', () => {
        setIsTrailer(false)
      })
    }
  }, [eventListener])

  useEffect(() => {
    if (data) {
      const fetchPlayInfo = async () => {
        const playInfoResult = await getData('GET', `get_play_info/${data.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
        if (playInfoResult) {
          setDataPlayInfo(playInfoResult)

          setOptions({
            // autoplay: true,
            // muted: true,
            loop: false,
            controls: true,
            responsive: true,
            fluid: true,
            inactivityTimeout: 4000,
            controlBar: {
              volumePanel: {
                inline: false
              }
            },
            html5: {
              hls: {
                limitRenditionByPlayerDimensions: false
              }
            }
            // sources: [{
            //   type: 'application/x-mpegURL',
            //   src: linkPlay
            // }]
          })
        }
      }

      fetchPlayInfo()

      const dataHashtagTemp = data.hashtags
      if (typeof dataHashtagTemp === 'string') {
        if (dataHashtagTemp !== '') {
          const dataHashtagArr = dataHashtagTemp.split(',')
          setHastagList(dataHashtagArr)
        }
      }

      // eslint-disable-next-line no-undef
      if (gtag) {
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
          page_title: data.title,
          page_location: `https://thvli.vn/truc-tiep/${slug}`,
          page_path: `/truc-tiep/${slug}`,
          send_to: 'G-RW9WHCKH4J'
        })
      }

      document.title = data.title

      // let linkPlayDetail = ''

      if (data.type === 6) {
        if (data.default_episode && data.default_episode.id) {
          setTitle(data.display_name ? data.display_name : data.episode_name)
        } else {
          setTitle(data.display_name ? data.display_name : data.episode_name)
        }
      } else {
        if (data.default_episode && data.default_episode.id) {
          setTitle(data.display_name ? data.display_name : data.default_episode.title)
        } else {
          setTitle(data.display_name ? data.display_name : data.title)
        }
      }

      setIsLoading(false)
      setIsLive(false)

      if (data?.content_ratings?.length > 0) {
        const ageRatingTemp = getAgeContentRating(
          data?.content_ratings
        )
        setAgeRating(ageRatingTemp)
      }

      intervalRef.current = setInterval(() => {
        handleIntervalEvent(data)
      }, [1000])

      // Set Timeout 5 phút
      const now = new Date()
      if (typeof data?.event_end_time === 'number') {
        const eventEndTime = new Date(data?.event_end_time * 1000)

        console.log(eventEndTime)
        if (eventEndTime) {
          const gapTime = eventEndTime.getTime() - now.getTime() - 120000

          timeoutRef.current = setTimeout(() => {
            const fetchDataLive = async () => {
              const resultTemp = await getData('GET', `get_detail/${slug}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
              if (resultTemp) {
                if (resultTemp.event_end_time !== data.event_end_time) {
                  clearInterval(intervalRef.current)
                  clearInterval(intervalEndRef.current)
                  clearTimeout(timeoutRef.current)

                  intervalEndRef.current = setInterval(() => {
                    handleIntervalEvent(resultTemp)
                  }, [1000])
                }
              }
            }

            fetchDataLive()
          }, gapTime)
        }
      }

      return () => {
        clearInterval(intervalRef.current)
      }
    }
  }, [data])

  const handleLinkPlayWithTrailer = () => {
    const now = new Date()

    if (typeof data?.event_start_time === 'number') {
      const eventStartTime = new Date(data?.event_start_time * 1000)

      if (eventStartTime) {
        if (now.getTime() < eventStartTime.getTime()) {
          const linkPlayTrailer = dataPlayInfo?.trailer_info?.data?.hls_link_play
          if (linkPlayTrailer && linkPlayTrailer !== '') {
            setIsTrailer(true)
            setSrc({
              type: 'application/x-mpegURL',
              src: linkPlayTrailer
            })
          } else {
            setIsTrailer(false)
          }
        } else {
          if (dataPlayInfo?.play_info?.data?.hls_link_play) {
            setIsTrailer(false)
          }
        }
      } else {
        if (dataPlayInfo?.play_info?.data?.hls_link_play) {
          setIsTrailer(false)
        }
      }
    } else {
      if (dataPlayInfo?.play_info?.data?.hls_link_play) {
        setIsTrailer(false)
      }
    }

    if (data.type === 6) {
      if (data.default_episode && data.default_episode.id) {
        setTitle(data.display_name ? data.display_name : data.episode_name)
      } else {
        if (data.play_info && data.play_info.data && data.play_info.data.hls_link_play) {
          setTitle(data.display_name ? data.display_name : data.episode_name)
        } else {
          const trailerListTemp = data.trailers
          if (Array.isArray(trailerListTemp) && trailerListTemp.length > 0) {
            const fetchDetailTrailer = async () => {
              try {
                const resultTrailer = await getData('GET', `get_detail/${trailerListTemp[0]?.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
                if (resultTrailer) {
                  setTitle(resultTrailer.display_name ? resultTrailer.display_name : resultTrailer.episode_name)
                }
              } catch (err) {
                console.log(err)
              }
            }

            fetchDetailTrailer()
          }
        }
      }
    } else {
      if (data.default_episode && data.default_episode.id) {
        setTitle(data.display_name ? data.display_name : data.default_episode.title)
      } else {
        if (data.play_info && data.play_info.data && data.play_info.data.hls_link_play) {
          setTitle(data.display_name ? data.display_name : data.title)
        } else {
          const trailerListTemp = data.trailers
          if (Array.isArray(trailerListTemp) && trailerListTemp.length > 0) {
            const fetchDetailTrailer = async () => {
              try {
                const resultTrailer = await getData('GET', `get_detail/${trailerListTemp[0]?.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
                if (resultTrailer) {
                  setTitle(resultTrailer.display_name ? resultTrailer.display_name : resultTrailer.episode_name)
                }
              } catch (err) {
                console.log(err)
              }
            }

            fetchDetailTrailer()
          }
        }
      }
    }
  }

  useEffect(() => {
    if (player) {
      handleLinkPlayWithTrailer()

      // player.on('ready', () => {
      //   window.scrollTo(0, 0)
      // })
      player.on('loadstart', () => {
        window.scrollTo(0, 0)

        const promisePlay = player.play()
        if (promisePlay !== undefined) {
          promisePlay.then(function () {
            // Autoplay started!
          }).catch(function (error) {
            console.log(error)
          })
        }
      })

      // User interactive
      player.on('useractive', (event) => {
        const reportErrorElement = document.getElementsByClassName('report-error-btn')
        reportErrorElement[0].style.display = 'block'

        const ageRatingElement = document.getElementsByClassName('detail-age-rating-wrapper')
        if (ageRatingElement && ageRatingElement[0]) {
          ageRatingElement[0].style.display = 'block'
        }
      })
      player.on('userinactive', (event) => {
        const reportErrorElement = document.getElementsByClassName('report-error-btn')
        reportErrorElement[0].style.display = 'none'

        const ageRatingElement = document.getElementsByClassName('detail-age-rating-wrapper')
        if (ageRatingElement && ageRatingElement[0]) {
          ageRatingElement[0].style.display = 'none'
        }
      })

      // Catch Error
      player.on('error', () => {
        // sentryException(`[VOD] - ${title}`, {
        //   link_play: player && player.currentSrc(),
        //   error: player.error()
        // })
      })
    }
  }, [player])

  useEffect(() => {
    if (intervalRef?.current) {
      return () => {
        clearInterval(intervalRef.current)
      }
    }
  }, [intervalRef])

  useEffect(() => {
    if (intervalEndRef?.current) {
      return () => {
        clearInterval(intervalEndRef.current)
      }
    }
  }, [intervalEndRef])

  useEffect(() => {
    if (timeoutRef?.current) {
      return () => {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [timeoutRef])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickShowModalReportError = () => {
    setIsShowModal(true)
  }

  const handleCloseModalReportError = (value) => {
    setIsShowModal(value)
  }

  const handlePlayerReady = (player) => {
    ref.current = player
    setPlayer(player)
  }

  const handleClickOverlayAds = () => {
    const overlayAds = document.getElementById('video-overlay-ads')
    if (overlayAds) {
      overlayAds.style.display = 'none'
    }
    if (player) {
      player.play()
    }
  }

  return (
    <div className="detail-live-event">
      <div className="row pt-4">
        <div className="col-0 col-md-2">
          <DetailAds data={data} position="left" />
        </div>
        <div className="col-12 col-lg-8">
          {countdownTime !== 'idle' &&
            <>
              {(!isLive && !isTrailer) &&
                <div className="countdown-container">
                  <img className='w-100 h-100' src={data?.images?.thumbnail} alt="" />

                  <div className='countdown-text'>
                    {countdownTime === null
                      ? <div>Chương trình đã kết thúc</div>
                      : <div>Phát sóng sau {countdownTime}</div>
                    }
                  </div>
                </div>
              }
            </>
          }

          <div data-vjs-player className="video-container" style={{ position: 'relative', display: (isLive || isTrailer) ? 'block' : 'none' }}>
            {ageRating?.image_url &&
              <div className='detail-age-rating-wrapper'>
                <img
                  className='detail-age-rating-icon'
                  src={ageRating?.image_url}
                  // src='https://s3-hcm.cloud.cmctelecom.vn/sctv-main-2/tenants/none_tenant/photos/t13_f5201dc0.png'
                />
              </div>
            }

            <div className="report-error-btn" onClick={handleClickShowModalReportError}>
              <FontAwesomeIcon className="report-icon" icon={faExclamationCircle} />
              <div className="report-text">Báo lỗi</div>
            </div>

            {options &&
              <VideoLiveEvent
                type="vod"
                options={options}
                onReady={handlePlayerReady}
                src={src}
              />
            }

            {/* Overlay Ads Pause */}
            <div className="video-overlay-ads" id="video-overlay-ads" onClick={handleClickOverlayAds}>
              <div className="overlay-in">

                <div id="mgid-ad-container">
                  <div className="desktop-ads">
                    <div id="M763376ScriptRootC1404321"></div>
                  </div>
                  <div className="mobile-ads">
                    <div id="M763376ScriptRootC1404323"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <DetailInfo data={data} title={title} hashtagList={hashtagList} />
        </div>
        <div className="col-0 col-md-2">
          <div className="banner-ads">
            <DetailAds data={data} position="right" />
          </div>
        </div>
      </div>

      <ModalReportError
        isShow={isShowModal}
        onClose={handleCloseModalReportError}
      />

      {isLoading && <Overlay />}
    </div>
  )
}

export default DetailLiveEvent
