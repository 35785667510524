import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, useLocation, useParams } from 'react-router-dom'
import { parse } from 'date-fns'
import Slider from 'react-slick'
// import { parseDate } from 'common/utils'
import { DFPSlotsProvider } from 'react-dfp'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlay, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Play, InfoCircle } from 'iconsax-react'
import { setProgress } from 'features/watching/watchingSlice'
import ModalFigure from 'features/core/ModalFigure'
import ImageHolder from 'features/core/ImageHolder'
import { imgholder } from 'constant/images'
import ImagePosterHolder from 'features/core/ImagePosterHolder'
import RibbonItemNewRelease from './RibbonItemNewRelease'
import RibbonItemTop from './RibbonItemTop'
import RibbonItemLiveEvent from './RibbonItemLiveEvent'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'

import { initializeApp } from 'firebase/app'
import { getDatabase, ref, onValue } from 'firebase/database'

import '../styles/ribbon.scss'
import '../styles/ribbon-euro.scss'

import logoThvli1 from '../styles/images/logo_thvli1.png'
import logoThvli2 from '../styles/images/logo_thvli2.png'
import logoThvli3 from '../styles/images/logo_thvli3.png'

const RibbonEuro = (props) => {
  const { data } = props

  const location = useLocation()
  const { slug } = useParams()
  const dispatch = useDispatch()

  const [modalContentId, setModalContentId] = useState(null)
  const [modalContentSlug, setModalContentSlug] = useState(null)
  const [modalContentEpisode, setModalContentEpisode] = useState(null)
  const [modalContentImage, setModalContentImage] = useState(null)
  const [modalContentDescription, setModalContentDescription] = useState(null)
  const [modalContentPublishDate, setModalContentPublishDate] = useState(null)
  const [modalContentNewRelease, setModalContentNewRelease] = useState(null)
  const [modalProgress, setModalProgress] = useState(null)
  const [isShowModal, setIsShowModal] = useState(false)

  const [teamList, setTeamList] = useState([])
  const [matchList, setMatchList] = useState([])
  const [groupList, setGroupList] = useState([])

  const daysOfWeek = [
    'Chủ nhật',
    'Thứ hai',
    'Thứ ba',
    'Thứ tư',
    'Thứ năm',
    'Thứ sáu',
    'Thứ bảy'
  ]

  const monthsOfYear = [
    'tháng 1',
    'tháng 2',
    'tháng 3',
    'tháng 4',
    'tháng 5',
    'tháng 6',
    'tháng 7',
    'tháng 8',
    'tháng 9',
    'tháng 10',
    'tháng 11',
    'tháng 12'
  ]

  const firebaseConfig = {
    apiKey: 'AIzaSyCkxCx869eG8aPFoZOc7K2U1bIfBnRr2os',
    authDomain: 'thvli-48223.firebaseapp.com',
    databaseURL: 'https://thvli-48223.firebaseio.com',
    projectId: 'thvli-48223',
    storageBucket: 'thvli-48223.appspot.com',
    messagingSenderId: '513556246464',
    appId: '1:513556246464:web:8f3b68253b4f3625a76225',
    measurementId: 'G-NW8BGJLPSG'
  }

  const app = initializeApp(firebaseConfig)

  // Initialize Realtime Database and get a reference to the service
  const database = getDatabase(app)

  useEffect(() => {
    const teamRef = ref(database, 'teams')
    onValue(teamRef, (snapshot) => {
      const data = snapshot.val()
      if (Array.isArray(data)) {
        // const dataTemp = data.map(item => {
        //   return {
        //     teamFlag: item.teamFlag,
        //     teamId: item.teamId,
        //     teamName: item.teamName
        //   }
        // })

        setTeamList(data)
      }
    })
  }, [])

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      const valueKey = typeof x[key] === 'string' ? x[key].slice(0, 10) : x[key];

      (rv[valueKey] = rv[valueKey] || []).push(x)
      return rv
    }, {})
  }

  const compare = (a, b) => {
    if (a.sort < b.sort) {
      return -1
    }
    if (a.sort > b.sort) {
      return 1
    }
    return 0
  }

  useEffect(() => {
    if (Array.isArray(teamList) && teamList.length > 0) {
      const matchRef = ref(database, 'matches')
      onValue(matchRef, (snapshot) => {
        const data = snapshot.val()

        if (Array.isArray(data)) {
          const dataObj = groupBy(data, 'calendar')

          const arrTemp = []
          for (const item in dataObj) {
            const dateParse = parse(item, 'MM-dd-yyyy', new Date())
            const dayData = dateParse.getDay()
            const monthData = dateParse.getMonth()
            const dateData = dateParse.getDate()
            const day = daysOfWeek[dayData]
            const month = monthsOfYear[monthData]

            const arrData = dataObj[item]
            const dataTemp = arrData.map(itemTemp => {
              const scheduleParse = typeof itemTemp.scheduleTime === 'string' ? itemTemp.scheduleTime.slice('-8') : ''

              const itemTeamOne = teamList.find(obj => obj.teamId === itemTemp.teamOneId)
              const itemTeamTwo = teamList.find(obj => obj.teamId === itemTemp.teamTwoId)

              return {
                ...itemTemp,
                teamOneName: itemTeamOne.teamName,
                teamOneFlag: itemTeamOne.teamFlag,
                teamTwoName: itemTeamTwo.teamName,
                teamTwoFlag: itemTeamTwo.teamFlag,
                scheduleTimeParse: scheduleParse
              }
            })

            arrTemp.push({
              calendar: item,
              calendarParse: `${day}, ngày ${dateData} ${month}`,
              data: dataTemp
            })
          }

          setMatchList(arrTemp)
        }
      })

      // Group
      const groupRef = ref(database, 'groups')
      onValue(groupRef, (snapshot) => {
        const data = snapshot.val()
        if (Array.isArray(data)) {
          const dataTemp = data.map(item => {
            const teamArrValue = item.teams
            const teamArr = Array.isArray(teamArrValue) ? teamArrValue.sort(compare) : []
            const teamArrTemp = Array.isArray(teamArr) && teamArr.map(itemChild => {
              const itemTeam = teamList.find(obj => obj.teamId === itemChild.teamId)

              return {
                // teamId: itemChild.teamId,
                // teamName: itemTeam.teamName,
                // teamFlag: itemTeam.teamFlag
                ...itemTeam
              }
            })

            return {
              ...item,
              name: typeof item.name === 'string' ? item.name.replace('Group', 'Bảng') : item.name,
              teams: teamArrTemp
            }
          })

          setGroupList(dataTemp)
        }
      })
    }
  }, [teamList])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const convertToPlain = (html) => {
    // Create a new div element
    const tempDivElement = document.createElement('div')

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html

    const htmlValue =
      tempDivElement.textContent || tempDivElement.innerText || ''

    tempDivElement.remove()

    // Retrieve the text property of the element
    return htmlValue
  }

  const handleClickShowModal = (e, item) => {
    if (item.images && item.images.cover && item.images.cover !== '') {
      setModalContentImage(item.images
        ? ((item.images.cover && item.images.cover) !== '' ? item.images.cover : imgholder)
        : imgholder)
    } else {
      setModalContentImage(item.images
        ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
        : imgholder)
    }

    const strippedHtml = convertToPlain(
      item.long_description
    )
    setModalContentDescription(strippedHtml)

    setModalContentPublishDate(item.publish_date)

    setModalContentId(item.id)
    setModalContentSlug((item.default_episode && item.default_episode.id) ? item.default_episode.id : item.slug)
    setModalContentEpisode(item?.default_episode?.episode)
    setModalProgress(item.progress)
    setModalContentNewRelease(item.new_release)
    setIsShowModal(true)
  }

  const handleCloseModal = (value) => {
    setIsShowModal(value)
    setModalContentId(null)
  }

  const handleClickWatching = (e, progress) => {
    dispatch(setProgress(progress))
  }

  const handleClickWatchingFromBegin = (e, progress) => {
    dispatch(setProgress(0))
  }

  return (
    <div className="ribbon px-0 px-md-4 pt-3 pt-md-5">
      <div className='row'>
        <div className='col-12 col-md-12'>
          <DFPSlotsProvider dfpNetworkId='23128043035'>
            {data && data.map((item, index) => {
              const itemArrTemp = item.items
              const itemArr = Array.isArray(itemArrTemp) ? (item?.type === 5 ? itemArrTemp.slice(0, 9) : itemArrTemp) : []

              return (
                <div key={index}>
                  {/* {index === 3 &&
                    <div className="banner-ads">
                        <AdSlot sizes={[[970, 90]]} adUnit='THVLi_Desktop_Middle_Banner_970x90' />
                    </div>
                  } */}

                  {itemArr?.length > 0 &&
                    <>
                      <div className="row pt-4">
                        <div className="col-md-12">
                          <h3 className="ribbon-title ps-1 ps-md-2 pb-0 pb-md-1">
                            <span>{item.name}</span>
                            <Link to={`${(location.pathname && location.pathname !== '' && location.pathname !== '/') ? location.pathname : '/page/trang-chu'}/${item.slug}/tat-ca`}>
                              {/* <span className="float-end text-all">Tất cả &#8250;</span> */}
                              <span className="text-all">Xem thêm &#8250;</span>
                            </Link>
                          </h3>
                        </div>
                      </div>

                      <div className="row container-wrapper">
                        <div className="col-md-12">
                          <Slider {...{
                            className: item.id === '4818436b-d7bf-4867-85ca-64203b11c694' ? 'live-ribbon' : `vod-ribbon ${(item?.type === 6) ? 'newrelease-ribbon' : ''}`,
                            dots: false,
                            infinite: false,
                            swipeToSlide: false,
                            speed: 500,
                            initialSlide: 0,
                            slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 4.5 : item?.type === 5 ? 4.5 : 5),
                            slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 4.5 : item?.type === 5 ? 4.5 : 5),
                            // slidesToShow: item.type === 1 ? 7 : 5,
                            // slidesToScroll: item.type === 1 ? 7 : 5,
                            responsive: [
                              { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                              { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                              { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                              { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                              { breakpoint: 1350, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 4.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 4.5 : 5) } }
                              // { breakpoint: 1200, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5) } },
                              // { breakpoint: 1500, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5) } }
                            ]
                          }}>
                            {Array.isArray(itemArr) && itemArr.map((itemChild, indexChild) => {
                              // const peopleArr = itemChild.people
                              // let actorStr = ''
                              // let directorStr = ''
                              // const publishDateStr = parseDate(itemChild.publish_date)

                              // if (Array.isArray(peopleArr)) {
                              //   for (let i = 0; i < peopleArr.length; i++) {
                              //     if (peopleArr[i] && peopleArr[i].role === 'actor') {
                              //       actorStr += peopleArr[i].name + ', '
                              //     }
                              //     if (peopleArr[i] && peopleArr[i].role === 'director') {
                              //       directorStr += peopleArr[i].name + ', '
                              //     }
                              //   }
                              // }

                              // actorStr = actorStr.substring(0, actorStr.length - 2)
                              // directorStr = directorStr.substring(0, directorStr.length - 2)

                              if (item?.type === 6) {
                                return (
                                  <RibbonItemNewRelease
                                    key={indexChild}
                                    indexChild={indexChild}
                                    handleClickShowModal={handleClickShowModal}
                                    item={item}
                                    itemChild={itemChild}
                                    slug={slug}
                                  />
                                )
                              } else if (item?.type === 5) { // Top 10
                                return (
                                  <RibbonItemTop
                                    key={indexChild}
                                    indexChild={indexChild}
                                    handleClickShowModal={handleClickShowModal}
                                    item={item}
                                    itemChild={itemChild}
                                    slug={slug}
                                  />
                                )
                              } else {
                                return (
                                  // <div key={indexChild} className="pb-1 pb-md-4 item-figure">
                                  <div key={indexChild} className="mb-1 mb-md-0 item-figure">
                                    {/* <Link to={`${itemChild.type === 5 ? '/live/' : '/detail/'}${itemChild.slug}`}> */}
                                      {/* <figure className="figure px-1 px-md-2"> */}
                                      <figure className="figure">
                                        <AgeRatingItem item={itemChild} />

                                        <NewReleaseItem
                                          item={itemChild}
                                          // isPoster={itemChild?.type === 1}
                                          isPoster={item?.type === 1}
                                        />

                                        <RibbonItemLiveEvent data={itemChild} />

                                        {itemChild.slug === 'phat-thanh'
                                          ? <a href="https://audio.thvl.vn/radio/phat-thanh" target="_blank" rel="noopener noreferrer">
                                              <div className="figure-overflow">
                                                <ImageHolder data={itemChild.images} />
                                              </div>
                                            </a>
                                          : <Link to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}>
                                              {/* <div className={`figure-overflow ${item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0' ? 'overflow_master_banner_mobile' : ''}`}> */}
                                                <div className="figure-overflow">

                                                  {/* <ImageHolder data={itemChild.images} /> */}

                                                  {/* {item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0'
                                                    ? <ImagePosterHolder data={itemChild.images} />
                                                    : <ImageHolder data={itemChild.images} />
                                                  } */}

                                                  {item.type === 1
                                                    ? <ImagePosterHolder data={itemChild.images} />
                                                    : <ImageHolder data={itemChild.images} />
                                                  }
                                                  {/* <ImageHolder data={itemChild.images} /> */}
                                              </div>
                                            </Link>
                                        }

                                        {itemChild.type !== 5 &&
                                          <div className={`info-detail ${item.type === 1 ? 'info_detail_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
                                            <Link
                                              to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}
                                              className="link-btn-play"
                                            >
                                              <button className={`btn btn-primary btn-play ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`}>
                                              {/* <button className="btn btn-primary btn-play"> */}
                                                {/* <FontAwesomeIcon icon={faPlay} className="icon-play me-2" /> */}
                                                <Play color="black" variant='Bold' size={12} className='me-1' />
                                                {item.type === 1 ? 'Xem ngay' : 'Xem'}
                                              </button>
                                            </Link>
                                            <button className={`btn btn-primary btn-info-detail ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
                                            {/* <button className="btn btn-primary btn-info-detail" onClick={e => handleClickShowModal(e, itemChild)}> */}
                                              {/* <FontAwesomeIcon icon={faInfoCircle} className="icon-info-detail me-2" /> */}
                                              <InfoCircle color="white" variant='Bold' size={12} className='me-1' />
                                              Thông tin
                                            </button>

                                            {/* {directorStr.length > 0 &&
                                              <div className="info-text">Đạo diễn: {directorStr}</div>
                                            }
                                            {actorStr.length > 0 &&
                                              <div className="info-text">Diễn viên: {actorStr}</div>
                                            }
                                            <div className="info-text pb-2">Xuất bản: {publishDateStr}</div> */}
                                          </div>
                                        }
                                      </figure>
                                    {/* </Link> */}
                                  </div>
                                )
                              }
                            })}
                          </Slider>
                        </div>
                      </div>
                    </>
                  }
                </div>
              )
            })}

            {/* <div className="banner-ads">
              <AdSlot sizes={[[970, 90]]} adUnit='THVLi_Desktop_Bottom_Banner_970x90' />
            </div> */}
          </DFPSlotsProvider>
        </div>
      </div>

      <div className='row row-euro-ribbon'>
        <div className='col-12 col-md-4 col-euro'>
          <div className='match-wrapper' id="match-wrapper">
            <div className='text-white text-match-title'>Lịch thi đấu</div>

            {Array.isArray(matchList) && matchList.map((item, index) => {
              const itemList = item.data

              return (
                <div key={index} className='match-divider'>
                  <div className='text-white text-calendar'>{item.calendarParse}</div>

                  {Array.isArray(itemList) && itemList.map((itemChild, indexChild) => {
                    const channelList = itemChild.channels

                    return (
                      <div key={indexChild} className={`match-container ${indexChild % 2 === 0 ? 'match-even' : 'match-odd'}`}>
                        <div className='team-container'>
                          <img src={itemChild.teamOneFlag} width={48} height={48} />
                          <div className='text-white mt-2'>{itemChild.teamOneName}</div>
                        </div>
                        <div className='match-time-container'>
                          <div className='text-white'>{itemChild.scheduleTimeParse}</div>
                          <div className='text-white'>{itemChild.score}</div>

                          <div className='channel-container-euro'>
                            {Array.isArray(channelList) && channelList.map((itemChannel, indexChannel) => {
                              return (
                                <div key={indexChannel} className='channel-item-euro'>
                                  {itemChannel === 'thvl1'
                                    ? <img src={logoThvli1} width={40} height={15} />
                                    : (itemChannel === 'thvl2'
                                        ? <img src={logoThvli2} width={40} height={15} />
                                        : (itemChannel === 'thvl3' ? <img src={logoThvli3} width={40} height={15} /> : <></>))}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <div className='team-container'>
                          <img src={itemChild.teamTwoFlag} width={48} height={48} />
                          <div className='text-white mt-2'>{itemChild.teamTwoName}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>

        <div className='col-12 col-md-8'>
          <div className='group-tab-wrapper' id="group-tab-wrapper">
          <div className='text-white text-standing-title'>Bảng xếp hạng</div>

            {Array.isArray(groupList) && groupList.map((item, index) => {
              const itemList = item.teams

              return (
                <div key={index} className={`group-wrapper ${index % 2 === 0 ? 'group-even' : 'group-odd'}`}>
                  <div className='group-title'>
                    <div className='text-white group-group'>{item.name}</div>
                    <div className='group-title-info'>
                      <div className='group-title-info-item large'>Trận</div>
                      <div className='group-title-info-item large'>Thắng</div>
                      <div className='group-title-info-item'>H</div>
                      <div className='group-title-info-item large'>Thua</div>
                      <div className='group-title-info-item'>BT</div>
                      <div className='group-title-info-item'>SBT</div>
                      <div className='group-title-info-item'>HS</div>
                      <div className='group-title-info-item'>Đ</div>
                    </div>
                  </div>

                  {Array.isArray(itemList) && itemList.map((itemChild, indexChild) => {
                    return (
                      <div key={indexChild} className='group-team-container'>
                        <div className='group-team'>
                          <div className='text-white'>{indexChild + 1}</div>
                          <img src={itemChild.teamFlag} width={40} height={40} />
                          <div className='text-white'>{itemChild.teamName}</div>
                        </div>
                        <div className='group-statistic'>
                          <div className='group-statistic-item large'>{itemChild.dd}</div>
                          <div className='group-statistic-item large'>{itemChild.thang}</div>
                          <div className='group-statistic-item'>{itemChild.h}</div>
                          <div className='group-statistic-item large'>{itemChild.thua}</div>
                          <div className='group-statistic-item'>{itemChild.bt}</div>
                          <div className='group-statistic-item'>{itemChild.sbt}</div>
                          <div className='group-statistic-item'>{itemChild.hs}</div>
                          <div className='group-statistic-item'>{itemChild.d}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <ModalFigure
        isShow={isShowModal}
        modalContentId={modalContentId}
        modalContentSlug={modalContentSlug}
        modalContentEpisode={modalContentEpisode}
        modalContentImage={modalContentImage}
        modalContentDescription={modalContentDescription}
        modalContentPublishDate={modalContentPublishDate}
        modalContentNewRelease={modalContentNewRelease}
        onClose={handleCloseModal}
        onClickPlay={e => handleClickWatching(e, modalProgress)}
        onClickPlayFromBegin={e => handleClickWatchingFromBegin(e, modalProgress)}
      />
    </div>
  )
}

RibbonEuro.propTypes = {
  data: PropTypes.array
}

RibbonEuro.defaultProps = {
  data: []
}

export default RibbonEuro
