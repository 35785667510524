/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import { useQuery } from 'react-query'
import { formatDate, formatTime, scrollVertical, scrollVerticalCenter, getData, getDataDetail, getDataServerTime, callApiAds } from 'common/utils'
import { sentryException } from 'common/sentry'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faStepForward, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { imgholder, imgRadioAnimation } from 'constant/images'
import { Refresh2, Forbidden, Calendar as CalendarIcon } from 'iconsax-react'
import Overlay from 'components/Overlay'
// import { trackingPlayer } from 'common/tracking'
// import XMLWriter from 'xml-writer'
import 'bootstrap/js/dist/modal'
import Calendar from 'react-calendar'
import ModalReportError from 'features/core/ModalReportError'
import moment from 'moment'
import Video from 'components/Video'
import packageJson from '../../../../package.json'
import imgInstall from '../styles/img-install.png'
import { v4 as uuidv4Package } from 'uuid'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-calendar/dist/Calendar.css'
import '../styles/live.scss'

const Live = (props) => {
  const history = useHistory()
  const { slug } = useParams()
  const ref = useRef()

  const dateConvert = new Date()
  const utcDate = Date.UTC(dateConvert.getUTCFullYear(), dateConvert.getUTCMonth(), dateConvert.getUTCDate(),
    dateConvert.getUTCHours(), dateConvert.getUTCMinutes(), dateConvert.getUTCSeconds())
  const dateGmt = new Date(utcDate)
  const currentTime = dateGmt.getTime()

  const [options, setOptions] = useState(null)
  // const [isLoading, setIsLoading] = useState(true)
  const [src, setSrc] = useState(null)
  const [player, setPlayer] = useState(null)
  const [title, setTitle] = useState('')
  const [channelId, setChannelId] = useState(null)
  const [scheduleList, setScheduleList] = useState([])
  const [schedule, setSchedule] = useState(null)
  const [epgList, setEpgList] = useState([])
  const [isShowCalendar, setIsShowCalendar] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState(0)
  const [currentEpg, setCurrentEpg] = useState(0)
  const [scheduleDate, setScheduleDate] = useState(new Date())
  // const [intervalProgress, setIntervalProgress] = useState(null)
  const [intervalLive, setIntervalLive] = useState(null)
  const [channelSlider, setChannelSlider] = useState(null)
  // const [muted, setMuted] = useState(true)

  const [adsManager, setAdsManager] = useState(null)
  // const [timeoutIsAdPlaying, setTimeoutIsAdPlaying] = useState(null)
  const [isHoverSkipAd, setIsHoverSkipAd] = useState(false)
  const [isAdPlaying, setIsAdPlaying] = useState(false)
  const [isCountering, setIsCountering] = useState(false)
  const [isSkipableAds, setIsSkipableAds] = useState(false)

  const [isBlackout, setIsBlackout] = useState(false)

  const [isShowModal, setIsShowModal] = useState(false)

  const [dataPlayInfo, setDataPlayInfo] = useState(null)

  // const [isPaused, setIsPaused] = useState(false)

  // Countdown Ads
  const [counterSkipAds, setCounterSkipAds] = useState(5)
  // const [adTagValue, setAdTagValue] = useState('')

  const { data, isError, error, refetch } = useQuery(['getDetailLive', slug], async () => {
    // return await getData('GET', `detail/${slug}`)
    return await getDataDetail('GET', `get_detail/${slug}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
  }, {
    retry: false,
    cacheTime: 0,
    staleTime: 0
  })

  const { data: dataAllChannel } = useQuery('getAllChannel', async () => {
    return await getData('GET', 'ribbon/a6ccbdff-5688-4b25-9989-5ce872603b0a/?platform=web')
  })

  const { data: dataEpg, isLoading: isLoadingEpg, isFetching: isFetchingEpg } = useQuery(['getAllChannel', channelId, schedule], async () => {
    if (channelId && schedule) {
      return await getData('GET', `epg/?channel_id=${channelId}&schedule_date=${schedule.value}&platform=web`)
    }
  })

  const heightScreen = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

  const eventListener = useCallback(() => {
  }, [epgList, currentEpg])

  useEffect(() => {
    if (isError && error) {
      if (error?.request?.status === 403) {
        const fetchDataServerTime = async () => {
          const resultServerTime = await getDataServerTime()
          if (resultServerTime) {
            const currentTimestamp = Date.now()
            const timeDiff = currentTimestamp - resultServerTime
            localStorage.setItem('TIME_NOW', currentTimestamp)
            localStorage.setItem('TIME_SERVER', resultServerTime)
            localStorage.setItem('TIME_DIFF', timeDiff)
          }

          setTimeout(() => {
            refetch()
          }, 200)
        }

        fetchDataServerTime()
      }
    }
  }, [isError, error])

  useEffect(() => {
    if (isAdPlaying) {
      if (counterSkipAds === 0) {
        setIsCountering(false)
      }

      const timer =
        counterSkipAds > 0 && setInterval(() => setCounterSkipAds(counterSkipAds - 1), 1000)
      return () => clearInterval(timer)
    }
  }, [counterSkipAds, isAdPlaying])

  useEffect(() => {
    if (player) {
      // Check Live
      setIntervalLive(setInterval(() => {
        const currentTimeLive = new Date().getTime()
        const epgCurrentData = epgList[currentEpg]
        const epgNextData = epgList[currentEpg + 1]

        if (epgCurrentData && epgNextData) {
          if (currentSchedule === 6 && currentTimeLive > (epgCurrentData.start_at * 1000) && currentTime < (epgCurrentData.end_at * 1000)) {
            if (currentTimeLive > (epgNextData.start_at * 1000)) {
              const epgListClone = [...epgList]
              setEpgList([])
              setEpgList(epgListClone)
              setCurrentEpg(currentEpg + 1)
            }
          }
        }
      }, 10000))

      player.on('ended', () => {
        const currentTimeLive = new Date().getTime()
        const epgNextData = epgList[currentEpg + 1]

        if (epgNextData) {
          if (currentSchedule === 6) {
            if (currentTimeLive < (epgNextData.start_at * 1000)) {
              // do nothing
            } else {
              if (player) {
                if (epgNextData.is_blackout === 1) {
                  setTimeout(() => {
                    player?.pause()
                  }, 2000)
                  setIsBlackout(true)
                } else {
                  setIsBlackout(false)
                  setSrc({
                    type: 'application/x-mpegURL',
                    src: epgNextData.link_play
                  })
                }
              }
              setCurrentEpg(currentEpg + 1)
            }
          } else if (currentSchedule > 6) {
            // do nothing
          } else {
            if (player) {
              if (epgNextData.is_blackout === 1) {
                setTimeout(() => {
                  player?.pause()
                }, 2000)
                setIsBlackout(true)
              } else {
                setIsBlackout(false)
                setSrc({
                  type: 'application/x-mpegURL',
                  src: epgNextData.link_play
                })
              }
            }
            setCurrentEpg(currentEpg + 1)
          }
        } else {
          // player.dispose();
        }
      })
    }
  }, [eventListener])

  useEffect(() => {
    const currentDate = new Date()
    const dateListTemp = []

    for (let i = 6; i > -2; i--) {
      const day = currentDate - (86400000 * i)
      dateListTemp.push({
        value: formatDate(day, 'yyyy-mm-dd'),
        label: formatDate(day, 'dd/mm/yyyy')
      })
    }

    setScheduleList(dateListTemp)
  }, [])

  // useEffect(() => {
  //   return () => {
  //     if (timeoutIsAdPlaying) {
  //       clearTimeout(timeoutIsAdPlaying)
  //     }
  //   }
  // }, [timeoutIsAdPlaying])

  useEffect(() => {
    const posterElement = document.getElementsByClassName('vjs-poster')
    if (slug === 'phat-thanh') {
      if (posterElement && posterElement[0]) {
        posterElement[0].style.display = 'block'
      }
    } else {
      if (posterElement && posterElement[0]) {
        posterElement[0].style.display = 'none'
      }
    }
  }, [slug])

  // useEffect(() => {
  //   return () => {
  //     if (intervalProgress) {
  //       clearInterval(intervalProgress)
  //     }
  //   }
  // }, [intervalProgress])
  useEffect(() => {
    return () => {
      if (intervalLive) {
        clearInterval(intervalLive)
      }
    }
  }, [intervalLive])

  useEffect(() => {
    if (dataAllChannel && channelSlider) {
      // const indexFind = dataAllChannel.items.findIndex((itemFind) => {
      //   return itemFind.slug === slug
      // })

      // setTimeout(() => {
      //   if (channelSlider) {
      //     channelSlider.slickGoTo(indexFind)
      //   }
      // }, 1000)
    }
  }, [dataAllChannel, channelSlider])

  const handleParamAdtagUrl = (titleParam, genreParam, linkPlayParam) => {
    let uuidv4Str = ''

    if (localStorage.getItem('adm_uuid')) {
      uuidv4Str = localStorage.getItem('adm_uuid')
    } else {
      const uuidv4 = uuidv4Package()
      localStorage.setItem('adm_uuid', uuidv4)
    }

    const paramStrTemp = `did=${uuidv4Str}\
&deviceid=${uuidv4Str}\
&bundleid=website.thvli.vn\
&vid=${slug}\
&app_name=THVLi\
&ua=${encodeURI(navigator.userAgent)}\
&content_id=${slug}\
&content_url=${window.location.href}\
&content_title=${encodeURI(titleParam)}\
&content_episode=${encodeURI(titleParam)}\
&content_genre=${encodeURI(genreParam)}\
&content_series=${encodeURI(titleParam)}\
&description_url=${encodeURI(titleParam)}\
&url=${window.location.href}\
&sid=${uuidv4Str}\
&app_version=${packageJson?.version}\
&u=${linkPlayParam}\
&cust_params=genre%3D${encodeURIComponent(genreParam)}`

    return paramStrTemp
  }

  useEffect(async () => {
    if (player) {
      const posterElement = document.getElementsByClassName('vjs-poster')
      if (slug === 'phat-thanh') {
        if (posterElement && posterElement[0]) {
          posterElement[0].style.display = 'block'
        }
      } else {
        if (posterElement && posterElement[0]) {
          posterElement[0].style.display = 'none'
        }
      }

      callApiAds(data.id)
        .then((result) => {
          let urlData = ''
          const adsResData = result.data.data

          // eslint-disable-next-line array-callback-return
          adsResData.map((adsItem, index) => {
            if (adsItem.type === 'PREROLL') {
              urlData = adsItem.url
            }
          })

          const urlReplace = typeof urlData === 'string' ? urlData.replace('&url=[REFERRER_URL]', '') : ''
          const urlReplaceTemp = urlReplace.replace('&correlator=[TIMESTAMP]', `&correlator=${Date.now()}`)

          const paramAdtagTemp = handleParamAdtagUrl(data.title, data.ads_genre, data.link_play)
          const urlParamTemp = urlReplaceTemp + '&' + paramAdtagTemp

          const overlayAds = document.getElementById('video-overlay-ads')
          if (overlayAds) {
            overlayAds.style.display = 'none'
          }

          player.ima({
            adTagUrl: urlParamTemp,
            contribAdsSettings: {
              liveCuePoints: false
            }
          })

          // setAdTagValue(urlData)
          setSrc({
            type: 'application/x-mpegURL',
            // src: data.link_play
            src: dataPlayInfo?.play_info?.data?.hls_link_play
          })
        })
        .catch((err) => {
          // player.ima({
          //   adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='
          // })

          setSrc({
            type: 'application/x-mpegURL',
            // src: data.link_play
            src: dataPlayInfo?.play_info?.data?.hls_link_play
          })
          console.log(err)
        })

      player.on('ready', () => {
        window.scrollTo(0, 0)
        // player.mute()

        // const promise = player.play()

        // if (promise !== undefined) {
        //   console.log('promise', promise)
        //   promise.then(() => {
        //   }).catch(() => {
        //     try {
        //       player.play()
        //     } catch {}
        //   })
        // }

        // if (player.paused()) {
        //   setIsPaused(true)
        // } else {
        //   setIsPaused(false)
        // }

        // console.log('player.paused()', player.paused())
        // if (player.paused()) {
        //   player.play()
        //   console.log('request play')
        // }
      })

      player.on('play', () => {
        // setIsPaused(false)
        // setMuted(false)
      })

      player.on('pause', () => {
        // setIsPaused(true)
      })

      player.on('loadstart', () => {
        window.scrollTo(0, 0)

        const promisePlay = player.play()
        if (promisePlay !== undefined) {
          promisePlay.then(function () {
            // Autoplay started!
          }).catch(function (error) {
            console.log(error)
          })
        }

        if (slug === 'phat-thanh') {
          player.currentTime(1)
        }
      })

      player.on('adskip', (event) => {
        console.log('adskip', event)
        setIsAdPlaying(false)
        setIsCountering(false)
        // player.play()
      })

      player.on('adend', (event) => {
        console.log('adend', event)
        setIsAdPlaying(false)
        setIsCountering(false)
        // player.play()
      })

      player.on('adtimeout', (event) => {
        console.log('adtimeout', event)
        setIsAdPlaying(false)
        setIsCountering(false)
        // player.play()
      })

      player.on('adserror', (event) => {
        console.log('adserror', event)
        setIsAdPlaying(false)
        setIsCountering(false)
        // player.play()
      })

      // User interactive
      player.on('useractive', (event) => {
        const reportErrorElement = document.getElementsByClassName('report-error-btn')
        reportErrorElement[0].style.display = 'block'
      })
      player.on('userinactive', (event) => {
        const reportErrorElement = document.getElementsByClassName('report-error-btn')
        reportErrorElement[0].style.display = 'none'
      })

      player.on('ads-ad-started', () => {
        setIsAdPlaying(true)
        setIsCountering(true)
        setCounterSkipAds(5)
      })

      player.on('ads-manager', (response) => {
        const adsManager = response.adsManager
        setAdsManager(adsManager)
        setIsSkipableAds(adsManager.getAdSkippableState())
      })

      // Catch Error
      player.on('error', (e) => {
        // console.log('player error', e)
        const playerError = player.error()
        if (playerError) {
          if (playerError?.error?.code === 2) {
            sentryException(`[LiveTV] - ${title}`, {
              link_play: player && player.currentSrc(),
              error: player.error()
            })
          }
        }
      })
    }
  }, [player])

  useEffect(async () => {
    if (data) {
      // eslint-disable-next-line no-undef
      if (gtag) {
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
          page_title: data.title,
          page_location: `https://thvli.vn/live/${slug}`,
          page_path: `/live/${slug}`,
          send_to: 'G-RW9WHCKH4J'
        })
      }

      document.title = data.title
      setChannelId(data.id)
      setTitle(data.title)

      const fetchPlayInfo = async () => {
        const resultPlayInfo = await getData('GET', `get_play_info/${data.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
        if (resultPlayInfo) {
          setDataPlayInfo(resultPlayInfo)

          if (player) {
            setSrc({
              type: 'application/x-mpegURL',
              src: resultPlayInfo?.play_info?.data?.hls_link_play
            })
          } else {
            if (slug === 'phat-thanh') {
              setOptions({
                poster: imgRadioAnimation,
                // autoplay: true,
                // muted: true,
                controls: true,
                responsive: true,
                // sources: [{
                //   type: 'application/x-mpegURL',
                //   src: data.link_play
                // }]
                html5: {
                  hls: {
                    limitRenditionByPlayerDimensions: false
                  }
                }
              })
            } else {
              setOptions({
                // autoplay: true,
                // muted: true,
                controls: true,
                responsive: true,
                // sources: [{
                //   type: 'application/x-mpegURL',
                //   src: data.link_play
                // }]
                html5: {
                  hls: {
                    limitRenditionByPlayerDimensions: false
                  }
                }
              })
            }
          }
        }
      }

      fetchPlayInfo()

      // const urlData = window.location.href
      // const ipResult = await axios({
      //   method: 'GET',
      //   url: 'https://api.ipify.org?format=json'
      // })
      // const ipData = ipResult.data && ipResult.data.ip
      // setAdTagUrl(`https://tv.ads.spotx.tv/vast/653389?w=640&h=480&url=${urlData}&ip=${ipData}`)

      // if (player) {
      //   setSrc({
      //     type: 'application/x-mpegURL',
      //     src: data.link_play
      //   })
      // } else {
      //   if (slug === 'phat-thanh') {
      //     setOptions({
      //       poster: imgRadioAnimation,
      //       // autoplay: true,
      //       // muted: true,
      //       controls: true,
      //       responsive: true,
      //       // sources: [{
      //       //   type: 'application/x-mpegURL',
      //       //   src: data.link_play
      //       // }]
      //       html5: {
      //         hls: {
      //           limitRenditionByPlayerDimensions: false
      //         }
      //       }
      //     })
      //   } else {
      //     setOptions({
      //       // autoplay: true,
      //       // muted: true,
      //       controls: true,
      //       responsive: true,
      //       // sources: [{
      //       //   type: 'application/x-mpegURL',
      //       //   src: data.link_play
      //       // }]
      //       html5: {
      //         hls: {
      //           limitRenditionByPlayerDimensions: false
      //         }
      //       }
      //     })
      //   }
      // }

      // setIsLoading(false)

      // Tracking event player
      // trackingPlayer(data)
      //   .then((response) => {
      //     console.log(response)

      //     // Sau khi hoàn tất sự kiện live started
      //     // Gọi api check progress theo các mốc thời gian
      //     let timeInterval = 0
      //     setIntervalProgress(setInterval(() => {
      //       timeInterval += 1

      //       if (timeInterval === 6 || timeInterval === 30 || timeInterval === 60 || timeInterval === 300 ||
      //         timeInterval === 600 || timeInterval === 1200 || timeInterval === 1800 ||
      //         timeInterval === 3600 || timeInterval === 7200) {
      //         trackingPlayer(data, timeInterval.toString())
      //           .then((response) => {
      //             console.log(response)
      //           })
      //           .catch((error) => {
      //             console.log(error)
      //             // sentryException(`[LiveTV] - ${title}`, {
      //             //   link_play: player && player.currentSrc(),
      //             //   error: error
      //             // })
      //           })
      //       }
      //     }, 1000))
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     // sentryException(`[LiveTV] - ${title}`, {
      //     //   link_play: player && player.currentSrc(),
      //     //   error: error
      //     // })
      //   })
    }
  }, [data])

  useEffect(() => {
    if (scheduleList) {
      setCurrentSchedule(6)
      setSchedule(scheduleList[6])
    }
  }, [scheduleList])

  useEffect(() => {
    if (dataEpg) {
      setEpgList(dataEpg.items)

      if (dataEpg.items) {
        if (currentSchedule === 6) {
          for (let i = 0; i < dataEpg.items.length; i++) {
            if (currentTime > (dataEpg.items[i].start_at * 1000) && currentTime < (dataEpg.items[i].end_at * 1000)) {
              setCurrentEpg(i)
              setTimeout(() => {
                scrollVertical('container-epg', 'container-epg', 'UP')
                scrollVerticalCenter('container-epg', `epg-${i}`, 'DOWN')
              }, 800)
            }
          }
        } else {
          setTimeout(() => {
            setCurrentEpg(-1)
            scrollVertical('container-epg', 'container-epg', 'UP')
          }, 800)
        }
      }
    }
  }, [dataEpg])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickShowModalReportError = () => {
    setIsShowModal(true)
  }

  const handleCloseModalReportError = (value) => {
    setIsShowModal(value)
  }

  const handlePlayerReady = (player) => {
    ref.current = player
    setPlayer(player)
  }

  const handleClickShowCalendar = () => {
    setIsShowCalendar(true)
  }

  const handleClickHideCalendar = () => {
    setIsShowCalendar(false)
  }

  const handleClickDate = (date) => {
    if (date) {
      const selectedDate = moment(date).format('YYYY-MM-DD')
      const indexFind = scheduleList.findIndex((item) => {
        return item.value === selectedDate
      })
      setCurrentSchedule(indexFind)
      setIsShowCalendar(false)
      setSchedule(scheduleList[indexFind])
      setScheduleDate(date)
    }
  }

  const handleClickEpg = (e, index) => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      if (Array.isArray(epgList)) {
        const epgItemTemp = epgList[index]

        if (epgItemTemp) {
          // eslint-disable-next-line no-undef
          gtag('event', 'page_view', {
            page_title: 'Catchup - ' + epgItemTemp?.title,
            page_location: `https://thvli.vn/live/${slug}`,
            page_path: `/live/${slug}`,
            send_to: 'G-RW9WHCKH4J',
            catchup_name: epgItemTemp?.title,
            start_at: epgItemTemp?.start_at,
            end_at: epgItemTemp?.end_at,
            currentTime: new Date().getTime()
          })
        }
      }
    }

    const epgCurrentData = epgList[index]

    if (epgCurrentData) {
      if (adsManager) {
        adsManager.stop()
        setIsAdPlaying(false)
      }

      if (currentSchedule === 6) {
        if (currentTime < (epgCurrentData.start_at * 1000)) {
          // do nothing
        } else {
          if (player) {
            if ((currentSchedule === 6 && currentTime > (epgCurrentData.start_at * 1000) && currentTime < (epgCurrentData.end_at * 1000))) {
              // if (epgCurrentData.is_blackout === 1) {
              //   player?.pause()
              //   setIsBlackout(true)
              // } else {
              //   setIsBlackout(false)
              //   setSrc({
              //     type: 'application/x-mpegURL',
              //     src: data?.link_play
              //   })
              // }

              const fetchPlayInfo = async () => {
                const resultPlayInfo = await getData('GET', `get_play_info/${data?.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
                if (resultPlayInfo) {
                  setDataPlayInfo(resultPlayInfo)

                  if (player) {
                    setSrc({
                      type: 'application/x-mpegURL',
                      src: resultPlayInfo?.play_info?.data?.hls_link_play
                    })
                  }
                }
              }

              fetchPlayInfo()
            } else {
              if (epgCurrentData.is_blackout === 1) {
                player?.pause()
                setIsBlackout(true)
              } else {
                setIsBlackout(false)
                setSrc({
                  type: 'application/x-mpegURL',
                  src: epgCurrentData.link_play
                })
              }
            }
          }
          setCurrentEpg(index)
        }
      } else if (currentSchedule > 6) {
        // do nothing
      } else {
        if (player) {
          if (epgCurrentData.is_blackout === 1) {
            player?.pause()
            setIsBlackout(true)
          } else {
            setIsBlackout(false)
            setSrc({
              type: 'application/x-mpegURL',
              src: epgCurrentData.link_play
            })
          }
        }
        setCurrentEpg(index)
      }
    }
  }

  const handleClickChannel = (e, item) => {
    // setIsLoading(true)
    setCurrentSchedule(6)
    setScheduleDate(new Date())
    setSchedule(scheduleList[6])
    window.scrollTo(0, 0)

    if (`/live/${item.slug}` === location.pathname) {
      if (item.slug === 'phat-thanh') {
        window.open('https://audio.thvl.vn/radio/phat-thanh', '_blank')
      } else {
        if (data) {
          setIsBlackout(false)
          setSrc({
            type: 'application/x-mpegURL',
            src: data.link_play
          })
        }

        if (dataEpg && dataEpg.items) {
          for (let i = 0; i < dataEpg.items.length; i++) {
            if (currentTime > (dataEpg.items[i].start_at * 1000) && currentTime < (dataEpg.items[i].end_at * 1000)) {
              setCurrentEpg(i)
              setTimeout(() => {
                scrollVertical('container-epg', 'container-epg', 'UP')
                scrollVertical('container-epg', `epg-${i}`, 'DOWN')
              }, 800)
            }
          }
        }
      }
    } else {
      if (item.slug === 'phat-thanh') {
        window.open('https://audio.thvl.vn/radio/phat-thanh', '_blank')
      } else {
        history.push(`/live/${item.slug}`)
        history.push('/empty')
        history.goBack()
      }

      // history.goBack()
      // window.location.href = `/live/${item.slug}`

      // if (player) {
      //   if (adsManager) {
      //     adsManager.stop()
      //     setIsAdPlaying(false)

      //     player.ima.changeAdTag(adTagValue)
      //     player.ima.requestAds()
      //   }
      //   // setIsAdPlaying(true)
      //   // setIsCountering(true)
      //   // setCounterSkipAds(5)
      // }
    }
  }

  // const PrevButton = ({ onClick }) => {
  //   return <a onClick={onClick} className='prevButton'><FontAwesomeIcon className="prevButtonIcon" icon={faChevronLeft} /></a>
  // }

  // const NextButton = ({ onClick }) => {
  //   return <a onClick={onClick} className='nextButton'><FontAwesomeIcon className="nextButtonIcon" icon={faChevronRight} /></a>
  // }

  const handleSkip = () => {
    if (adsManager) {
      adsManager.stop()
      setIsAdPlaying(false)
    }
  }

  const handleHoverSkipAds = () => {
    setIsHoverSkipAd(!isHoverSkipAd)
  }

  const handleClickInstallApp = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      let appstoreURL = ''

      if (navigator.userAgent.match(/Android/i)) {
        appstoreURL = 'https://play.google.com/store/apps/details?id=vn.thvl.app'
      }

      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        appstoreURL = 'itms-apps://itunes.apple.com/app/thvli/id1185316310'
      }

      window.location = appstoreURL

      // let pathname = window.location.pathname
      // if (pathname && pathname.charAt(0) === '/') {
      //   pathname = pathname.substring(1)
      // }

      // const pathArray = pathname.trim().split('/')
      // let slug = ''
      // let deeplinkPath = 'thvli://'

      // if (pathArray.length > 1) {
      //   slug = pathArray[1]
      //   if (pathArray[0] === 'detail') {
      //     deeplinkPath = 'thvli://entity/' + slug
      //   } else if (pathArray[0] === 'live') {
      //     deeplinkPath = 'thvli://live/' + slug
      //   }
      // }

      // const now = new Date().valueOf()
      // setTimeout(function () {
      //   if (new Date().valueOf() - now > 100) {
      //     window.location = appstoreURL
      //   }
      // }, 2000)

      // window.location = deeplinkPath
    }
  }

  return (
    <div className="live">
      <div className="row player-container">
        <div className="col-12 col-md-12 col-lg-8" style={{ position: 'relative', minHeight: '200px' }}>
          {isBlackout &&
            <div className='black-out-container'>
              <div>Chương trình đang tạm thời bị gián đoạn</div>
              <div>vì lý do bản quyền.</div>
              <div>Mong quý khán giả thông cảm và quay lại sau.</div>
            </div>
          }

          <div data-vjs-player style={{ display: isBlackout ? 'none' : 'block' }}>
            {/* {isPaused ? <div style={{ color: '#FFFFFF', position: 'absolute', zIndex: 999 }} onClick={() => player.play()}>Play</div> : null} */}
            <div className="report-error-btn" onClick={handleClickShowModalReportError}>
              <FontAwesomeIcon className="report-icon" icon={faExclamationCircle} />
              <div className="report-text">Báo lỗi</div>
            </div>
            {options &&
              <Video
                type="live"
                options={options}
                onReady={handlePlayerReady}
                src={src}
              // muted={muted}
              />
            }
          </div>

          {(isAdPlaying && isCountering && !isSkipableAds) &&
            <div
              style={{
                position: 'absolute',
                opacity: 1,
                bottom: '18px',
                cursor: 'default',
                padding: '15px 0 15px 15px',
                pointerEvents: 'auto',
                right: 0,
                zIndex: 1112
              }}
            >
              <div
                // className="btn btn-primary"
                style={{
                  minWidth: '90px',
                  opacity: 1,
                  // background: 'rgba(0,0,0,0.8)',
                  backgroundColor: 'black',
                  padding: '10px'
                }}
              // style={{
              //   position: 'absolute',
              //   bottom: 50,
              //   right: 40,
              //   zIndex: 1112,
              //   backgroundColor: 'black',
              //   color: 'white',
              //   border: 'none',
              //   borderRadius: 0
              // }}
              >
                <div
                  style={{
                    color: '#e6e6e6',
                    opacity: 1,
                    fontFamily: 'arial,sans-serif',
                    fontWeight: 'normal',
                    fontSize: '11px',
                    lineHeight: '21px',
                    paddingRight: 0,
                    textAlign: 'center',
                    display: 'inline-block',
                    width: '100%',
                    verticalAlign: 'middle'
                  }}
                >
                  {counterSkipAds}
                </div>
              </div>
            </div>
          }

          {(isAdPlaying && !isCountering && !isSkipableAds) &&
            <div
              style={{
                position: 'absolute',
                opacity: 1,
                bottom: '18px',
                cursor: 'default',
                padding: '15px 0 15px 15px',
                pointerEvents: 'auto',
                right: 0,
                zIndex: 1112,
                minWidth: '180px'
              }}
            >
              <button
                style={{
                  opacity: 1,
                  backgroundColor: isHoverSkipAd ? '#4d4d4d' : 'black',
                  border: '1px solid rgba(255,255,255,0.5)',
                  borderRight: 0,
                  boxSizing: 'content-box',
                  color: '#fff',
                  cursor: 'pointer',
                  fontFamily: 'arial,sans-serif',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  textAlign: 'center',
                  lineHeight: 'normal',
                  // minWidth: 0,
                  padding: '15px 70px 15px 100px',
                  // width: 'auto',
                  margin: 0
                }}
                // className="btn btn-primary custom-skip-ads-button"
                // style={{
                //   position: 'absolute',
                //   bottom: 50,
                //   right: 40,
                //   zIndex: 1112,
                //   backgroundColor: isHoverSkipAd ? '#4d4d4d' : 'black',
                //   color: 'white',
                //   border: 'none',
                //   borderRadius: 0
                // }}
                onClick={handleSkip}
                onMouseEnter={handleHoverSkipAds}
                onMouseLeave={handleHoverSkipAds}
              >
                Skip Ads
                <FontAwesomeIcon className="ms-2" icon={faStepForward} />
              </button>
            </div>
          }
        </div>

        <div className='col-12 row-install-app'>
          <div className='px-2 mt-4'>
            <img src={imgInstall} className='img-fluid' alt="" onClick={handleClickInstallApp} />
          </div>
        </div>

        {/* Channel List */}
        <div className="col-12 pt-4 col-md-12 col-lg-8 offset-lg-2 channel-container-list-mobile">
          <Slider ref={slider => setChannelSlider(slider)} {...{
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            // prevArrow: <PrevButton />,
            // nextArrow: <NextButton />,
            responsive: [
              { breakpoint: 480, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 687, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 768, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 1024, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 1350, settings: { slidesToShow: 5, slidesToScroll: 1 } }
            ]
          }}>
            {dataAllChannel && dataAllChannel.items && dataAllChannel.items.map((item, index) => {
              const indexFind = dataAllChannel.items.findIndex((itemFind) => {
                return itemFind.slug === slug
              })

              return (
                <figure key={index} className="figure px-2 px-md-2" onClick={e => handleClickChannel(e, item)}>
                  {indexFind === index && <div className="activeDot"></div>}
                  <img
                    className="figure-img img-fluid rounded-block w-100 channel-live"
                    style={{ cursor: 'pointer' }}
                    src={item.images
                      ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
                      : imgholder
                    }
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src = imgholder
                    }}
                  />
                </figure>
              )
            })}
          </Slider>
        </div>

        <div className="col-12 col-md-12 col-lg-4">
          <div className="row catchup-title pt-1 pt-md-0 pb-3">
            <div className="col-6 col-md-6">
              <div className="live-epg-calendar-live">Lịch phát sóng</div>
              {/* Lịch phát sóng {isPaused ? ' Pause' : ' Play'} */}
            </div>
            <div className="col-6 col-md-6">
              <div className="float-end calendar-epg" onClick={handleClickShowCalendar}>
                <CalendarIcon color="white" variant='Outline' size={22} className='me-2 mb-1' role='button' />
                {/* <FontAwesomeIcon className="me-2" icon={faCalendar} role="button" /> */}
                <span role="button">
                  {currentSchedule === 5
                    ? 'Hôm qua'
                    : currentSchedule === 6
                      ? 'Hôm nay'
                      : currentSchedule === 7
                        ? 'Ngày mai'
                        : scheduleList[currentSchedule] && scheduleList[currentSchedule].label
                  }
                </span>
              </div>
              {isShowCalendar &&
                <div className='loadingOverlayContainer'>
                  <div className='calendarCenter'>
                    <div className='closeCalendarButton' onClick={handleClickHideCalendar}><FontAwesomeIcon icon={faTimes} style={{ color: 'black' }} /></div>
                    <Calendar
                      // onChange={(date) => this._onCalendarChange(date)}
                      // onClickDecade={() => this._onClickDecade()}
                      onClickDay={(date) => handleClickDate(date)}
                      value={scheduleDate}
                      maxDate={new Date(moment().add(1, 'd'))}
                      minDate={new Date(moment().add(-6, 'd'))}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
          <ul id="container-epg" className="list-group epg" style={{ height: `${heightScreen - 200}px` }}>
            {(isLoadingEpg || isFetchingEpg)
              ? <Overlay />
              : epgList && epgList.map((item, index) => {
                return (
                  <li
                    id={`epg-${index}`}
                    key={index}
                    className={`list-group-item 
                      ${(currentSchedule > 6 || (currentSchedule === 6 && currentTime < (item.start_at * 1000))) ? 'epg-disabled' : ''} 
                      ${(currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000)) ? 'epg-is-live' : (index === currentEpg ? (isBlackout ? 'epg-is-blackout' : 'epg-is-live') : '')}
                    `}
                    onClick={e => handleClickEpg(e, index)}
                  >
                    <div className="row">
                      {/* <div className="col-3 col-md-3 col-xxl-2"> */}
                      <div className="col col-time-epg">
                        <div className="epg-title title-content-epg">{formatTime(item.start_at)}</div>
                        <div>
                          {/* {(currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                            ? <div className="epg-current-live"><span className="epg-dot"></span>Live</div>
                            : (index === currentEpg) ? <div className="epg-current-playing">Đang phát</div> : null
                          } */}

                          {(currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                            ? <div className="epg-current-live"><span className="epg-dot"></span>Live</div>
                            : (index === currentEpg) ? <div className="epg-current-playing-catchup">Đang phát</div> : null
                          }
                        </div>
                        {/* <figure className="figure">
                          <div className="figure-overflow">
                            <img
                              className="figure-img img-fluid rounded-block w-100 img-thumbnail"
                              src={item.images
                                ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
                                : imgholder
                              }
                              onError={(e) => {
                                e.target.onerror = null
                                e.target.src = imgholder
                              }}
                            />
                          </div>
                        </figure> */}
                      </div>
                      {/* <div className="col-8 col-md-8 col-xxl-9 pb-4 pb-md-0 epg-title"> */}
                      <div className="col pb-4 pb-md-0 epg-title">
                        <div className="title-content-epg">{item.title}</div>
                        {/* <div>
                          <span><FontAwesomeIcon icon={faClock} /></span>
                          <span className="ps-2">{formatTime(item.start_at)} - {formatTime(item.end_at)}</span>
                        </div> */}
                        {/* <div>
                          {(currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                            ? <div className="epg-current-live">Live</div>
                            : (index === currentEpg) ? <div className="epg-current-playing">Đang phát</div> : null
                          }
                        </div> */}
                      </div>
                      {/* <div className="col-1 col-md-1 pb-4 pb-md-0 ps-1 epg-title"> */}
                      <div className="col-1 col-md-1 pb-4 pb-md-0 ps-1 epg-title">
                        {(currentSchedule > 6 || (currentSchedule === 6 && currentTime < (item.start_at * 1000)))
                          ? (currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                              ? <></>
                            // : <Notification color="white" variant='Bold' size={24} className='me-1' />
                              : <></>
                          : (currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                              ? <></>
                              : <>
                              {isBlackout
                                ? <>
                                  {index === currentEpg
                                    ? <Forbidden color="white" variant='Bold' size={20} className='me-1' />
                                    : <Refresh2 color="white" variant='Bold' size={20} className='me-1' />
                                  }
                                </>
                                : <Refresh2 color="white" variant='Bold' size={20} className='me-1' />
                              }
                            </>
                        }
                      </div>
                    </div>

                    <hr className="epg-divider" />
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>

      {/* Channel List */}
      <div className="row pt-4 pt-md-5 pb-0 pb-md-4 channel-container-list">
        <div className="col-12 col-md-12 col-lg-8 offset-lg-2">
          <Slider ref={slider => setChannelSlider(slider)} {...{
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            // prevArrow: <PrevButton />,
            // nextArrow: <NextButton />,
            responsive: [
              { breakpoint: 480, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 687, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 768, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 1024, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 1350, settings: { slidesToShow: 5, slidesToScroll: 1 } }
            ]
          }}>
            {dataAllChannel && dataAllChannel.items && dataAllChannel.items.map((item, index) => {
              const indexFind = dataAllChannel.items.findIndex((itemFind) => {
                return itemFind.slug === slug
              })

              return (
                <figure key={index} className="figure px-2 px-md-2" onClick={e => handleClickChannel(e, item)}>
                  {indexFind === index && <div className="activeDot"></div>}
                  <img
                    className="figure-img img-fluid rounded-block w-100 channel-live"
                    style={{ cursor: 'pointer' }}
                    src={item.images
                      ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
                      : imgholder
                    }
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src = imgholder
                    }}
                  />
                </figure>
              )
            })}
          </Slider>
        </div>
      </div>

      <ModalReportError
        isShow={isShowModal}
        onClose={handleCloseModalReportError}
      />
    </div>
  )
}

export default Live
