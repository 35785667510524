import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DFPSlotsProvider, AdSlot } from 'react-dfp'
import '../styles/detail-ads.scss'

const DetailAds = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { data, position } = props

  const [adsCategory, setAdsCategory] = useState('')
  const [adsHashtag, setAdsHashtag] = useState('')
  const [adsGenre, setAdsGenre] = useState('')
  const [adsCountry, setAdsCountry] = useState('')
  const [adsPartner, setAdsPartner] = useState('')
  const [adsYear, setAdsYear] = useState('')

  useEffect(() => {
    if (data && data.metadata) {
      const category = data.metadata.category
      if (category) {
        setAdsCategory(category.length > 0 ? category[0].value : '')
      }

      const hashtag = data.metadata.hashtag
      if (hashtag) {
        setAdsHashtag(hashtag.length > 0 ? hashtag[0].value : '')
      }

      const genre = data.metadata.genre
      if (genre) {
        setAdsGenre(genre.length > 0 ? genre[0].value : '')
      }

      const country = data.metadata.country
      if (country) {
        setAdsCountry(country.length > 0 ? country[0].value : '')
      }

      const partner = data.metadata.partner
      if (partner) {
        setAdsPartner(partner.length > 0 ? partner[0].value : '')
      }

      const year = data.metadata.year
      if (year) {
        setAdsYear(year.length > 0 ? year[0].value : '')
      }
    }
  }, [data])

  return (
    <div className="detail-ads">
      <DFPSlotsProvider dfpNetworkId='23128043035' targetingArguments={{ category: adsCategory, hashtag: adsHashtag, genre: adsGenre, country: adsCountry, partner: adsPartner, year: adsYear }}>
        {position === 'left'
          ? <AdSlot sizes={[[160, 600]]} adUnit='THVLi_Desktop_Left_Player_Banner_160x600' />
          : <AdSlot sizes={[[160, 600]]} adUnit='THVLi_Desktop_Right_Player_Banner_160x600' />
        }
      </DFPSlotsProvider>
    </div>
  )
}

DetailAds.propTypes = {
  data: PropTypes.object,
  position: PropTypes.string
}

DetailAds.defaultProps = {
  data: null,
  position: 'left'
}

export default DetailAds
