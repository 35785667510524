import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, useLocation, useParams } from 'react-router-dom'
import Slider from 'react-slick'
// import { parseDate } from 'common/utils'
import { DFPSlotsProvider, AdSlot } from 'react-dfp'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlay, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Play, InfoCircle } from 'iconsax-react'
import { setProgress } from 'features/watching/watchingSlice'
import ModalFigure from 'features/core/ModalFigure'
import ImageHolder from 'features/core/ImageHolder'
import { imgholder } from 'constant/images'
import ImagePosterHolder from 'features/core/ImagePosterHolder'
import RibbonItemCW from './RibbonItemCW'
import RibbonItemNewRelease from './RibbonItemNewRelease'
import RibbonItemTop from './RibbonItemTop'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import RibbonItemLiveEvent from './RibbonItemLiveEvent'
import '../styles/ribbon.scss'

const Ribbon = (props) => {
  const { data } = props

  const location = useLocation()
  const { slug } = useParams()
  const dispatch = useDispatch()

  const [modalContentId, setModalContentId] = useState(null)
  const [modalContentSlug, setModalContentSlug] = useState(null)
  const [modalContentEpisode, setModalContentEpisode] = useState(null)
  const [modalContentImage, setModalContentImage] = useState(null)
  const [modalContentDescription, setModalContentDescription] = useState(null)
  const [modalContentPublishDate, setModalContentPublishDate] = useState(null)
  const [modalContentNewRelease, setModalContentNewRelease] = useState(null)
  const [modalProgress, setModalProgress] = useState(null)
  const [isShowModal, setIsShowModal] = useState(false)

  const [watchingList, setWatchingList] = useState([])

  const [watchingDeleteItem, setWatchingDeleteItem] = useState(null)

  useEffect(() => {
    if (data) { // load đồng bộ với ribbon
      if (localStorage.getItem('adm_token')) {
        axios.get(`${process.env.REACT_APP_API_URL}progress/?page=0&limit=15`, {
          headers: {
            Authorization: `${localStorage.getItem('adm_token')}`
          }
        })
          .then(res => {
            const dataTemp = res.data && res.data.items

            if (dataTemp && Array.isArray(dataTemp)) {
              setWatchingList(dataTemp)
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }, [data])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const convertToPlain = (html) => {
    // Create a new div element
    const tempDivElement = document.createElement('div')

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html

    const htmlValue =
      tempDivElement.textContent || tempDivElement.innerText || ''

    tempDivElement.remove()

    // Retrieve the text property of the element
    return htmlValue
  }

  const handleClickShowModal = (e, item) => {
    if (item.images && item.images.cover && item.images.cover !== '') {
      setModalContentImage(item.images
        ? ((item.images.cover && item.images.cover) !== '' ? item.images.cover : imgholder)
        : imgholder)
    } else {
      setModalContentImage(item.images
        ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
        : imgholder)
    }

    const strippedHtml = convertToPlain(
      item.long_description
    )
    setModalContentDescription(strippedHtml)

    setModalContentPublishDate(item.publish_date)

    setModalContentId(item.id)
    setModalContentSlug((item.default_episode && item.default_episode.id) ? item.default_episode.id : item.slug)
    setModalContentEpisode(item?.default_episode?.episode)
    setModalProgress(item.progress)
    setModalContentNewRelease(item.new_release)
    setIsShowModal(true)
  }

  const handleCloseModal = (value) => {
    setIsShowModal(value)
    setModalContentId(null)
  }

  const handleClickWatching = (e, progress) => {
    dispatch(setProgress(progress))
  }

  const handleClickWatchingFromBegin = (e, progress) => {
    dispatch(setProgress(0))
  }

  const onWatchingDeleteItem = (value) => {
    setWatchingDeleteItem(value)
  }

  const handleClickDeleteWatching = () => {
    const itemId = (watchingDeleteItem?.default_episode && watchingDeleteItem?.default_episode.id) ? watchingDeleteItem.default_episode.id : watchingDeleteItem?.id

    if (localStorage.getItem('adm_token')) {
      axios.delete(`${process.env.REACT_APP_API_URL}progress-detail/${itemId}/`, {
        headers: {
          Authorization: `${localStorage.getItem('adm_token')}`
        }
      })
        .then(res => {
          console.log(res)
          const successDeleteWatchingModalButton = document.getElementById('successDeleteWatchingModalButton')
          if (successDeleteWatchingModalButton) {
            successDeleteWatchingModalButton.click()
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const handleClickSuccessDeleteWatching = () => {
    window.location.reload()
  }

  return (
    <div className="ribbon px-0 px-md-4 pt-3 pt-md-5">
      <DFPSlotsProvider dfpNetworkId='23128043035'>
        {(watchingList && watchingList.length > 0) &&
          <div>
            <div className="row pt-4">
              <div className="col-md-12">
                <h3 className="ribbon-title ps-1 ps-md-2 pb-0 pb-md-1">
                  <span>Đang xem</span>
                  <Link to="/watching">
                    {/* <span className="float-end text-all">Tất cả &#8250;</span> */}
                    <span className="text-all">Xem thêm &#8250;</span>
                  </Link>
                </h3>
              </div>
            </div>

            <div className="row container-wrapper">
              <div className="col-md-12">
                <Slider {...{
                  className: 'watching-ribbon',
                  dots: false,
                  infinite: false,
                  swipeToSlide: false,
                  speed: 500,
                  initialSlide: 0,
                  slidesToShow: 5,
                  slidesToScroll: 5,
                  responsive: [
                    { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                    { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                    { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                    { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                    { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                  ]
                }}>
                  {watchingList && Array.isArray(watchingList) && watchingList.map((itemChild, indexChild) => {
                    // const peopleArr = itemChild.people
                    // let actorStr = ''
                    // let directorStr = ''
                    // const publishDateStr = parseDate(itemChild.publish_date)

                    // if (Array.isArray(peopleArr)) {
                    //   for (let i = 0; i < peopleArr.length; i++) {
                    //     if (peopleArr[i] && peopleArr[i].role === 'actor') {
                    //       actorStr += peopleArr[i].name + ', '
                    //     }
                    //     if (peopleArr[i] && peopleArr[i].role === 'director') {
                    //       directorStr += peopleArr[i].name + ', '
                    //     }
                    //   }
                    // }

                    // actorStr = actorStr.substring(0, actorStr.length - 2)
                    // directorStr = directorStr.substring(0, directorStr.length - 2)

                    // return (
                    //   <div key={indexChild} className="pb-1 pb-md-0 item-figure">
                    //       <figure className="figure">
                    //         <div className="figure-overflow">
                    //           <Link to={`${itemChild.type === 5 ? '/live/' : `/detail/${slug || 'trang-chu'}/dang-xem/`}${(itemChild.default_episode && itemChild.default_episode.id) ? itemChild.default_episode.id : itemChild.slug}`} onClick={e => handleClickWatching(e, itemChild.progress)}>
                    //             <ImageHolder data={itemChild.images} />
                    //           </Link>
                    //         </div>
                    //         {itemChild.type !== 5 &&
                    //           <div className="info-detail" onClick={e => handleClickShowModal(e, itemChild)}>
                    //             <Link to={`${itemChild.type === 5 ? '/live/' : `/detail/${slug || 'trang-chu'}/dang-xem/`}${(itemChild.default_episode && itemChild.default_episode.id) ? itemChild.default_episode.id : itemChild.slug}`} onClick={e => handleClickWatching(e, itemChild.progress)}>
                    //               <button className="btn btn-primary btn-play">
                    //                 <Play color="black" variant='Bold' size={12} className='me-1' />
                    //                 Xem
                    //               </button>
                    //             </Link>
                    //             <button className="btn btn-primary btn-info-detail" onClick={e => handleClickShowModal(e, itemChild)}>
                    //               <InfoCircle color="white" variant='Bold' size={12} className='me-1' />
                    //               Thông tin
                    //             </button>
                    //           </div>
                    //         }
                    //       </figure>
                    //   </div>
                    // )

                    return (
                      <RibbonItemCW key={indexChild} index={indexChild} handleClickShowModal={handleClickShowModal} onWatchingDeleteItem={onWatchingDeleteItem} item={itemChild} slug={slug} />
                    )
                  })}

                  {(watchingList.length === 1) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }
                  {(watchingList.length === 1) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }
                  {(watchingList.length === 1) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }
                  {(watchingList.length === 1) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }

                  {(watchingList.length === 2) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }
                  {(watchingList.length === 2) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }
                  {(watchingList.length === 2) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }

                  {(watchingList.length === 3) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }
                  {(watchingList.length === 3) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }

                  {(watchingList.length === 4) &&
                    <div className="pb-1 pb-md-4">
                      <figure className="figure px-1 px-md-2 figure-hide-item">
                        <div className="figure-overflow">
                          <ImageHolder />
                        </div>
                      </figure>
                    </div>
                  }
                </Slider>
              </div>
            </div>
          </div>
        }

        {data && data.map((item, index) => {
          const itemArrTemp = item.items
          const itemArr = Array.isArray(itemArrTemp) ? (item?.type === 5 ? itemArrTemp.slice(0, 9) : itemArrTemp) : []

          return (
            <div key={index}>
              {index === 3 &&
                <div className="banner-ads">
                    <AdSlot sizes={[[970, 90]]} adUnit='THVLi_Desktop_Middle_Banner_970x90' />
                </div>
              }

              {itemArr?.length > 0 &&
                <>
                  <div className="row pt-4">
                    <div className="col-md-12">
                      <h3 className="ribbon-title ps-1 ps-md-2 pb-0 pb-md-1">
                        <span>{item.name}</span>
                        <Link to={`${(location.pathname && location.pathname !== '' && location.pathname !== '/') ? location.pathname : '/page/trang-chu'}/${item.slug}/tat-ca`}>
                          {/* <span className="float-end text-all">Tất cả &#8250;</span> */}
                          <span className="text-all">Xem thêm &#8250;</span>
                        </Link>
                      </h3>
                    </div>
                  </div>

                  <div className="row container-wrapper">
                    <div className="col-md-12">
                      <Slider {...{
                        className: item.id === '4818436b-d7bf-4867-85ca-64203b11c694' ? 'live-ribbon' : `vod-ribbon ${(item?.type === 6) ? 'newrelease-ribbon' : ''}`,
                        dots: false,
                        infinite: false,
                        swipeToSlide: false,
                        speed: 500,
                        initialSlide: 0,
                        slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 4.5 : item?.type === 5 ? 4.5 : 5),
                        slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 4.5 : item?.type === 5 ? 4.5 : 5),
                        // slidesToShow: item.type === 1 ? 7 : 5,
                        // slidesToScroll: item.type === 1 ? 7 : 5,
                        responsive: [
                          { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                          { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                          { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                          { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                          { breakpoint: 1350, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 4.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 4.5 : 5) } }
                          // { breakpoint: 1200, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5) } },
                          // { breakpoint: 1500, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5) } }
                        ]
                      }}>
                        {Array.isArray(itemArr) && itemArr.map((itemChild, indexChild) => {
                          // const peopleArr = itemChild.people
                          // let actorStr = ''
                          // let directorStr = ''
                          // const publishDateStr = parseDate(itemChild.publish_date)

                          // if (Array.isArray(peopleArr)) {
                          //   for (let i = 0; i < peopleArr.length; i++) {
                          //     if (peopleArr[i] && peopleArr[i].role === 'actor') {
                          //       actorStr += peopleArr[i].name + ', '
                          //     }
                          //     if (peopleArr[i] && peopleArr[i].role === 'director') {
                          //       directorStr += peopleArr[i].name + ', '
                          //     }
                          //   }
                          // }

                          // actorStr = actorStr.substring(0, actorStr.length - 2)
                          // directorStr = directorStr.substring(0, directorStr.length - 2)

                          if (item?.type === 6) {
                            return (
                              <RibbonItemNewRelease
                                key={indexChild}
                                indexChild={indexChild}
                                handleClickShowModal={handleClickShowModal}
                                item={item}
                                itemChild={itemChild}
                                slug={slug}
                              />
                            )
                          } else if (item?.type === 5) { // Top 10
                            return (
                              <RibbonItemTop
                                key={indexChild}
                                indexChild={indexChild}
                                handleClickShowModal={handleClickShowModal}
                                item={item}
                                itemChild={itemChild}
                                slug={slug}
                              />
                            )
                          } else {
                            return (
                              // <div key={indexChild} className="pb-1 pb-md-4 item-figure">
                              <div key={indexChild} className="mb-1 mb-md-0 item-figure">
                                {/* <Link to={`${itemChild.type === 5 ? '/live/' : '/detail/'}${itemChild.slug}`}> */}
                                  {/* <figure className="figure px-1 px-md-2"> */}
                                  <figure className="figure">
                                    <AgeRatingItem item={itemChild} />

                                    <NewReleaseItem
                                      item={itemChild}
                                      // isPoster={itemChild?.type === 1}
                                      isPoster={item?.type === 1}
                                    />

                                    <RibbonItemLiveEvent data={itemChild} />

                                    {itemChild.slug === 'phat-thanh'
                                      ? <a href="https://audio.thvl.vn/radio/phat-thanh" target="_blank" rel="noopener noreferrer">
                                          <div className="figure-overflow">
                                            <ImageHolder data={itemChild.images} />
                                          </div>
                                        </a>
                                      : <Link to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}>
                                          {/* <div className={`figure-overflow ${item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0' ? 'overflow_master_banner_mobile' : ''}`}> */}
                                            <div className="figure-overflow">

                                              {/* <ImageHolder data={itemChild.images} /> */}

                                              {/* {item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0'
                                                ? <ImagePosterHolder data={itemChild.images} />
                                                : <ImageHolder data={itemChild.images} />
                                              } */}

                                              {item.type === 1
                                                ? <ImagePosterHolder data={itemChild.images} />
                                                : <ImageHolder data={itemChild.images} />
                                              }
                                              {/* <ImageHolder data={itemChild.images} /> */}
                                          </div>
                                        </Link>
                                    }

                                    {itemChild.type !== 5 &&
                                      <div className={`info-detail ${item.type === 1 ? 'info_detail_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
                                        <Link
                                          to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}
                                          className="link-btn-play"
                                        >
                                          <button className={`btn btn-primary btn-play ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`}>
                                          {/* <button className="btn btn-primary btn-play"> */}
                                            {/* <FontAwesomeIcon icon={faPlay} className="icon-play me-2" /> */}
                                            <Play color="black" variant='Bold' size={12} className='me-1' />
                                            {item.type === 1 ? 'Xem ngay' : 'Xem'}
                                          </button>
                                        </Link>
                                        <button className={`btn btn-primary btn-info-detail ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
                                        {/* <button className="btn btn-primary btn-info-detail" onClick={e => handleClickShowModal(e, itemChild)}> */}
                                          {/* <FontAwesomeIcon icon={faInfoCircle} className="icon-info-detail me-2" /> */}
                                          <InfoCircle color="white" variant='Bold' size={12} className='me-1' />
                                          Thông tin
                                        </button>

                                        {/* {directorStr.length > 0 &&
                                          <div className="info-text">Đạo diễn: {directorStr}</div>
                                        }
                                        {actorStr.length > 0 &&
                                          <div className="info-text">Diễn viên: {actorStr}</div>
                                        }
                                        <div className="info-text pb-2">Xuất bản: {publishDateStr}</div> */}
                                      </div>
                                    }
                                  </figure>
                                {/* </Link> */}
                              </div>
                            )
                          }
                        })}
                      </Slider>
                    </div>
                  </div>
                </>
              }
            </div>
          )
        })}

        <div className="banner-ads">
          <AdSlot sizes={[[970, 90]]} adUnit='THVLi_Desktop_Bottom_Banner_970x90' />
        </div>

        <ModalFigure
          isShow={isShowModal}
          modalContentId={modalContentId}
          modalContentSlug={modalContentSlug}
          modalContentEpisode={modalContentEpisode}
          modalContentImage={modalContentImage}
          modalContentDescription={modalContentDescription}
          modalContentPublishDate={modalContentPublishDate}
          modalContentNewRelease={modalContentNewRelease}
          onClose={handleCloseModal}
          onClickPlay={e => handleClickWatching(e, modalProgress)}
          onClickPlayFromBegin={e => handleClickWatchingFromBegin(e, modalProgress)}
        />

        {/* Xóa đang xem */}
        <div className="modal fade" id="deleteWatchingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Thông báo</h5>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>
              <div className="modal-body">
                Bạn có chắc chắn muốn xóa nội dung đang xem?
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Hủy</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClickDeleteWatching}>Đồng ý</button>
              </div>
            </div>
          </div>
        </div>

        <button style={{ display: 'none' }} type="button" id="deleteWatchingModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#deleteWatchingModal">
          Launch static backdrop modal
        </button>

        <div className="modal fade" id="successDeleteWatchingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Thông báo</h5>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>
              <div className="modal-body">
                Xóa nội dung thành công.
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClickSuccessDeleteWatching}>Đồng ý</button>
              </div>
            </div>
          </div>
        </div>

        <button style={{ display: 'none' }} type="button" id="successDeleteWatchingModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#successDeleteWatchingModal">
          Launch static backdrop modal
        </button>
      </DFPSlotsProvider>
    </div>
  )
}

Ribbon.propTypes = {
  data: PropTypes.array
}

Ribbon.defaultProps = {
  data: []
}

export default Ribbon
